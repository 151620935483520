<ng-container *ngIf="userLoggedIn">
    <ng-container *ngIf="type == 'product-box'">
        <div class="product-box__wishlist" [ngClass]="{'product-box__wishlist--active': isOnAnyList()}" (click)="wishListToggle($event)">
            <div class="icon">
                <svg>
                    <use *ngIf="isOnAnyList();" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#heart-filled"/>
                    <use *ngIf="!isOnAnyList();" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#heart"/>
                </svg>
            </div>
        </div>

        <ng-container *ngTemplateOutlet="wishListInner"></ng-container>
    </ng-container>

    <ng-container *ngIf="type == 'product-row'">
        <div class="product-row__wishlist" [ngClass]="{'product-row__wishlist--active': isOnAnyList()}" (click)="wishListToggle($event)">
            <div class="icon">
                <svg>
                    <use *ngIf="isOnAnyList();" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#heart-filled"/>
                    <use *ngIf="!isOnAnyList();" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#heart"/>
                </svg>
            </div>

            <ng-container *ngTemplateOutlet="wishListInner"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="type != 'product-box' && type != 'product-row'">
        <div class="detail-links__item">
            <a href="javascript:void(0);" class="detail-links__btn" (click)="wishListToggle($event)">
                <div class="icon">
                    <svg>
                        <use *ngIf="isOnAnyList();" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#heart-filled"/>
                        <use *ngIf="!isOnAnyList();" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#heart"/>
                    </svg>
                </div>

                {{sen['account-wishlists-h1']}}
            </a>

            <ng-container *ngTemplateOutlet="wishListInner"></ng-container>
        </div>
    </ng-container>
</ng-container>

<ng-template #wishListInner>
    <div class="wishlist js-wishlist" [hidden]="true" (click)="$event.stopPropagation()">
        <div class="wishlist__in">
            <div class="wishlist__title heading-h4">
                {{sen['account-wishlists']}}
            </div>

            <div class="wishlist__list">
                <ng-container *ngFor="let wishList of wishListSvc.wishLists">
                    <div class="wishlist__item" (click)="onItemToWishListClicked(wishList.hash)">
                        <div class="form-checkbox">
                            <input type="checkbox" id="{{wishList.id}}_wl" [checked]="isOnList(wishList.hash)">

                            <label for="{{wishList.id}}_wl">
                                {{wishList.name}}
                            </label>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="wishlist__bottom">
                <a *ngIf="!wishListAddMode; else elseBlock" href="javascript:void(0);" class="btn btn--primary btn--small btn--red" (click)="initCreateWishList()">
                    {{sen['detail-create-wishlist']}}
                </a>

                <ng-template #elseBlock>
                    <div class="wishlist-add">
                        <div class="wishlist-add__form">
                            <input #newWishlist class="wishlist-add__input" (keyup)="wishListKeyUp($event, newWishlist.value)" value="Nový" type="text">

                            <button class="wishlist-add__btn wishlist__btn--check btn btn--small btn--black" (click)="createWishList(newWishlist.value)">
                                {{sen['app-save']}}
                            </button>
                        </div>

                        <button class="wishlist-add__cancel" (click)="wishListAddMode = false">
                            <span class="icon">
                                <svg>
                                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cross-circle"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</ng-template>