import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IProductAvailability} from "../commons/commons";
import {Observable} from "rxjs";
import {IObjectCacheRepository, IObjectCacheResultItem, ObjectCache} from "../../../helpers/object-cache";
import {ToQueryStringParam} from "../../../helpers/string.helper";

@Injectable({
    providedIn: 'root'
})
export class AvailabilityService {

    private cache: ObjectCache<IProductAvailability, string> = null;

    constructor(
        http: HttpClient
    ) {
        this.cache = new ObjectCache<IProductAvailability, string>(new AvailabilityDataSource(http));
    }

    public GetOnlineAvailability(productCode: string): IProductAvailability {
        return this.cache.Get(productCode);
    }
}

export class AvailabilityDataSource implements IObjectCacheRepository<IProductAvailability, string> {

    constructor(
        private http: HttpClient
    ) {
    }

    GetByIds(ids: string[]): Observable<IObjectCacheResultItem<IProductAvailability, string>[]> {
        return this.http.get<IObjectCacheResultItem<IProductAvailability, string>[]>(`api/availability/get-online-availability-by-codes?qs=${ToQueryStringParam(ids)}`);
    }

}
