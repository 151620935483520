/**
 * Created by jiri.kopel on 06.03.2019.
 */
import {Component, ElementRef, ViewChild} from "@angular/core";
import {SearchSelector, Translatable, SearchRequest} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {SearchService} from "../../services/search.service";

declare let $: any;
declare let AutoComplete:any;

@Component({
    selector: 'cmp-luigi-suggest',
    templateUrl: '../../tpl/luigi-suggest.html'
})
export class LuigiSuggestComponent extends Translatable {

    @ViewChild('phrase', { static: true }) phrase: ElementRef;

    searchBoxVisible: boolean = false;
    suggestVisible: boolean = false;
    suggestResult: SearchSelector;
    enterFired: Subject<any> = new Subject<any>();
    ngUnsubscribe: Subject<any> = new Subject<any>();

    trackerId: string;

    constructor(dataSvc: DataService, seSvc: SettingsService, private http: HttpClient, private router: Router,
                private searchSvc: SearchService) {
        super(dataSvc, seSvc);

        this.http.get('api/luigis/settings')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
                this.trackerId = res.TrackerId;
                this.initScripts();
            });
    }

    ngAfterViewInit(): void {
        addEventListener('click', () => {
            this.suggestVisible = false;
        });
    }

    LuigisAutocomplete(): void {
        let trackerid = this.trackerId;
        AutoComplete({
            Layout: 'line',
            ShowBranding: true,
            TrackerId: trackerid,
            Types: [
                {
                    type: 'item',
                    name: this.sen['luigis-items'],//'Produkty',
                    size: 8
                },
                {
                    type: 'category',
                    name: this.sen['luigis-category'],//'Kategorie',
                    size: 6
                },
            ],
            FormatForDisplay: (result: any) => {
                let allAttributes = result.attributes;
                if (result.type == "item") {
                    allAttributes.price+= " "+ allAttributes['currency'];

                }
                return result;

            },
            Select: (e: MouseEvent, item: any, _row: any) => {
                e.preventDefault();
                this.router.navigateByUrl(item.url).then(() => {});
                return true;
            }
        }, '#js-search')
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.enterFired.complete();
    }

    searchKeyUp(evt: KeyboardEvent) {
        if (evt.key === 'Escape') {
            this.suggestVisible = false;
        }
    }

    search(phrase: string): void {
        if (phrase.length == 0) return;
        this.phrase.nativeElement.value = '';
        this.suggestVisible = false;
        this.router.navigateByUrl(`/vysledky-vyhledavani?q=${phrase}`).then(() => {});
    }

    searchKeyPress(evt: KeyboardEvent, phrase: string): void {
        this.searchSvc.fireSearchInputChanged(phrase);

        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            this.search(phrase);
            this.suggestVisible = false;
            this.enterFired.next();
            return;
        }
    }

    getSuggest(phrase: string): any {
        let request: SearchRequest = {
            phrase: decodeURI(phrase),
            pageIndex: 1,
            pageSize: 5,
            forSuggest: true
        };
        return this.http.post(`api/search`, request);
    }

    public toggleSearch(): void {
        if (!this.searchBoxVisible) {
            $('.header__search').show();
            this.searchBoxVisible = true;
        }
        else {
            $('.header__search').hide();
            this.searchBoxVisible = false;
        }
    }

    private initScripts(): void {
        let analytics = document.createElement('script');
        analytics.defer = true;
        analytics.src = 'https://cdn.luigisbox.com/netdirect.js';
        analytics.setAttribute('data-luigisbox-tracker-id', this.trackerId);

        let style = document.createElement('link');
        style.setAttribute('rel', 'stylesheet');
        style.href = 'https://cdn.luigisbox.com/autocomplete.css';

        let auto = document.createElement('script');
        auto.defer = true;
        auto.onload = () => { this.LuigisAutocomplete() };
        auto.src = 'https://cdn.luigisbox.com/autocomplete.js';

        document.body.appendChild(analytics);
        document.body.appendChild(style);
        document.body.appendChild(auto);

        /*
                    <script async="" data-luigisbox-tracker-id="@ViewBag.LuigisBox.TrackerId" src="https://cdn.luigisbox.com/netdirect.js" id="luigis_tracker"></script>
                    <link rel="stylesheet" href="https://cdn.luigisbox.com/autocomplete.css" />
                    <script src="https://cdn.luigisbox.com/autocomplete.js" async></script>
        */
    }
}