import {Injectable} from '@angular/core';
import {AsyncSubject, Subject} from "rxjs";
import {IGoogleSettings} from "../interfaces/IGoogleSettings";

declare let google: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleApiService {

    scriptLoaded: AsyncSubject<boolean> = new AsyncSubject();
    googleApiResponse: Subject<string> = new Subject<string>();
    ClientId: string;
    apiInitialized: boolean;

    constructor() {
    }

    public Init(settings: IGoogleSettings): void {
        this.ClientId = settings.ClientId;
        this.addScript('https://accounts.google.com/gsi/client').then(()=>{

            google.accounts.id.initialize({
                client_id: this.ClientId,
                callback: (response: any) => {
                    this.googleApiResponse.next(response.credential);
                }
            });

            this.scriptLoaded.next(true);
            this.scriptLoaded.complete();
        });
    }

    public oneTapLogin(): void {
        google.accounts.id.prompt(); // also display the One Tap dialog
    }

    /**
     * Returns JWT token
     * @param selector
     */
    public renderBtn(selector: string): void {
        const button = document.querySelectorAll(selector)[0];
        if(!button) console.error('Missing anchor element to render');
        google.accounts.id.renderButton(
            button,
            {theme: "outline", size: "large"}
        );
    }

    private addScript(src: string): Promise<any> {
        return new Promise<any>((resolve) => {
            const id = 'google-sign-in-api';
            let js: HTMLScriptElement = null;
            let fjs = document.getElementsByTagName('script')[0];
            if (document.getElementById(id)) {
                return;
            }
            js = <HTMLScriptElement>document.createElement('script');
            js.id = id;
            js.src = src;
            js.onload = () => {
                resolve(null);
            }
            fjs.parentNode.appendChild(js);
        });
    }
}
