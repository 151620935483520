import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlreadySignedUpDialogComponent} from './already-signed-up-dialog/already-signed-up-dialog.component';


@NgModule({
    declarations: [
        AlreadySignedUpDialogComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AlreadySignedUpDialogComponent
    ],
    entryComponents: [
        AlreadySignedUpDialogComponent
    ]
})
export class AlreadySignedUpDialogModule {
}
