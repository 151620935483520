<div class="help-box-menu">
    <div class="help-box-menu__title">
        {{sen['adva-academy-title']}}
    </div>

    <a [routerLink]="['/c/' + categoryItem?.id + '/' + categoryItem?.seoUrl]" class="help-box-menu__subtitle">
        {{categoryItem?.displayName}}
    </a>

    <div *ngIf="categoryItem?.subcategories?.length > 0" class="help-box-menu__content">
        <div class="help-box-menu__posts">
            <ng-container *ngFor="let subChild of categoryItem.subcategories; let last = last">
                <a [routerLink]="['/c/' + subChild.id + '/' + subChild.seoUrl]" class="help-box-menu__post">
                    {{subChild.displayName}}
                </a>
            </ng-container>
        </div>
    </div>

    <div class="help-box-menu__text">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#wink" />
            </svg>
        </div>

        <span [innerHTML]="sen['ask-expert--line4']"></span>
    </div>

    <div class="help-box-menu__actions">
        <a [routerLink]="'/akademie/vse'" class="btn btn--small btn--red">
            {{sen['ask-expert--button']}}
        </a>
    </div>
</div>
