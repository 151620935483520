import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HoverClassDirective} from "./hower-class.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HoverClassDirective
    ],
    exports: [
        HoverClassDirective
    ]
})

export class HoverClassModule  {}