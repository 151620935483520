/**
 * @description
 * Product listing in catalogue. Switchable grid / list.
 */
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {ProductSelector} from "../product/common";
import {CategoryService} from "../../services/category.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ProductViewType} from "../product-view/commons/commons";

@Component({
    selector: 'cmp-product-list',
    templateUrl: '../../tpl/product-list.html',
    styleUrls: ['../../assets/styles/2-components/product-list.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ProductListComponent extends Translatable {

    ProductViewType = ProductViewType;

    @Input() ViewType: ProductViewType = ProductViewType.Box;

    @Input() isInCategory: boolean;
    private _products: Array<ProductSelector> = null;
    @Input() set products(value: ProductSelector[]) {
        this._products = value;
    }

    get products(): ProductSelector[] {
        return this._products;
    }

    @Input() cultureId: number;
    @Input() styleClass: string;
    @Input() modificator: string;

    categoryId: number;
    currencyCode: string;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private catSvc: CategoryService) {
        super(dataSvc, seSvc);

        this.currencyCode = seSvc.culture.currencyCode;
        this.cultureId = this.catSvc.cultureId;
        this.categoryId = this.catSvc.categoryId;
    }

    ngOnInit(): void {
        if (this.isInCategory) {
            if (this.catSvc.products) {
                this.products = this.catSvc.products.products;
            }
            this.cultureId = this.catSvc.cultureId;
            this.categoryId = this.catSvc.categoryId;
        }


        if (this.isInCategory) {

            this.catSvc.productDataChanged
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.cultureId = this.catSvc.cultureId;
                    this.categoryId = this.catSvc.categoryId;
                    if (this.catSvc.products) {
                        this.products = this.catSvc.products.products;
                    } else {
                        this.products = null;
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
