import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FixedClassDirective} from './fixed-class.directive';
import {FixedClass2Directive} from './fixed-class2.directive';

@NgModule({
    declarations: [
        FixedClassDirective,
        FixedClass2Directive
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FixedClassDirective,
        FixedClass2Directive
    ]
})
export class FixedClassModule {
}
