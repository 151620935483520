import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {AdvaAcademyMenuComponent} from "./adva-academy-menu.component";
import {CustomPipesModule} from "../pipe/custom-pipes.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CustomPipesModule
    ],
    declarations: [AdvaAcademyMenuComponent],
    exports: [AdvaAcademyMenuComponent]
})

export class AdvaAcademyMenuModule {
}
