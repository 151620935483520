import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DebugBoxComponent} from './debug-box/debug-box.component';

@NgModule({
    declarations: [
        DebugBoxComponent
    ],
    exports: [
        DebugBoxComponent
    ],
    imports: [
        CommonModule
    ]
})
export class DebugModule {
}
