<div class="notify-stripe" *ngIf="showNotification">
	<div class="container">
		<div class="notify-stripe__icon" (click)="hide()">
			<div class="icon">
				<svg>
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cross-circle" />
				</svg>
			</div>
		</div>

		<div class="notify-stripe__content" [innerHtml]="html" *ngIf="html"></div>
	</div>
</div>
