import {Component} from '@angular/core';
import {SettingsService} from "../../../services/settings.service";
import {DataService} from "../../../services/data.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {GeneralDialogRef, IMessageBoxButton, IMessageBoxConfig} from "../common";
import {Translatable} from "../../../interfaces/general";
import {GeneralDialogConfig} from "../general-dialog-config";

@Component({
    selector: 'cmp-general-dialog-message-box',
    templateUrl: './general-dialog-message-box.component.html'
})
export class GeneralDialogMessageBoxComponent<T> extends Translatable {

    message: SafeHtml;
    buttons: IMessageBoxButton<T>[];

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private sanitizer: DomSanitizer,
        private dialogRef: GeneralDialogRef,
        private config: GeneralDialogConfig<IMessageBoxConfig<T>>
    ) {
        super(dataSvc, seSvc);

        if (this.dialogRef) {
        }
        if (this.config && this.config.data) {
            this.message = this.sanitizer.bypassSecurityTrustHtml(<string>(this.config.data.message));
            this.buttons = this.config.data.buttons
        }

    }

    ngOnInit(): void {
    }

    close(result: string): void {
        this.dialogRef.close(result);
    }
}