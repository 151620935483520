<div *ngIf="article?.childArticles?.length > 0" class="footer-links">
    <ng-container *ngFor="let child of article?.childArticles" >
        <a  *ngIf="child.url?.length && child.url.startsWith('http')"
            [href]="child.url"
            class="footer-links__item">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-right"/>
                </svg>
            </div>
            {{child.name}}
        </a>
        <a *ngIf="!child.url?.length || !child.url.startsWith('http')"
           [routerLink]="child.seoUrl"
           class="footer-links__item">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-right"/>
                </svg>
            </div>
            {{child.name}}
        </a>
    </ng-container>
</div>