<div class="help-box" [class]="classModificator ? 'help-box--'+classModificator : ''">
    <div class="help-box__subtitle">
        {{sen['ask-expert--line1']}}
    </div>

    <h2 class="help-box__title heading-h2">
        {{sen['ask-expert--line2']}}
    </h2>

    <div class="help-box__text">
        {{sen['ask-expert--line3']}}
    </div>

    <div class="help-box__in">
        <div class="help-box-item">
            <div class="help-box-item__image">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#phone" />
                    </svg>
                </div>
            </div>

            <div class="help-box-item__content">
                <div class="help-box-item__text" [innerHTML]="(phoneNoteHtml || '')"></div>

                <a href="tel: {{sen['ask-expert--contact1--line2']}}" class="help-box-item__value">
                    {{sen['ask-expert--contact1--line2']}}
                </a>
            </div>
        </div>

        <div class="help-box-item">
            <div class="help-box-item__image">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#email" />
                    </svg>
                </div>
            </div>

            <div class="help-box-item__content">
                <div class="help-box-item__text" [innerHTML]="(emailNoteHtml || '')"></div>

                <a href="mailto: {{sen['ask-expert--contact2--line2']}}" class="help-box-item__value">
                    {{sen['ask-expert--contact2--line2']}}
                </a>
            </div>
        </div>
    </div>

    <div class="help-box__text help-box__text--center" [innerHTML]="sen['ask-expert--line4']">
    </div>

    <div class="help-box__actions">
        <a [routerLink]="['/akademie']" class="btn btn--medium btn--red">{{sen['ask-expert--button']}}</a>
    </div>
</div>