<div class="mini-cart" *ngIf="place == 'menu'">
    cart
</div>

<div class="mini-cart" *ngIf="place != 'menu'">
    <a [routerLink]="['/kosik/obsah']" class="mini-cart__link">
        <div class="mini-cart__count">
            <ng-container *ngIf="!ItemCount()">
                0
            </ng-container>

            <ng-container *ngIf="ItemCount()">
                {{ItemCount() | number:'1.0-0'}}
            </ng-container>
        </div>

        <div class="mini-cart__image">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cart" />
                </svg>
            </div>
        </div>

        <div class="mini-cart__content">
            <div class="mini-cart__value">
                <ng-container *ngIf="!ItemCount()">
                    0
                </ng-container>

                <ng-container *ngIf="ItemCount()">
                    <cmp-price [price]="cartSvc.cart?.priceWithoutPaymentAndDeliveryWithVat"></cmp-price>
                </ng-container>
            </div>
        </div>
    </a>

    <div *ngIf="ItemCount()" class="mini-cart-products">
        <div class="mini-cart-products__inner">
            <div class="mini-cart-products__head">
                <div class="mini-cart-products__col mini-cart-products__col--product">
                    {{sen['mini-cart--product']}}
                </div>

                <div class="mini-cart-products__col mini-cart-products__col--amount">
                    {{sen['mini-cart--amount']}}
                </div>

                <div class="mini-cart-products__col mini-cart-products__col--price">
                    {{sen['mini-cart--price']}}
                </div>

                <div class="mini-cart-products__col mini-cart-products__col--remove">
                </div>
            </div>

            <div class="mini-cart-products__body">
                <div *ngFor="let item of cartSvc?.productList" class="mini-cart-products__item">
                    <a [routerLink]="['/v/' + item.productDetail.id + '/' +  item.productDetail.seoUrl]" class="mini-cart-products__image">
                        <picture>
                            <source [srcset]="(imagePathPrefix + '/cart_indicator/60/50/' + item.productDetail.imagePath) | webp" type="image/webp" />
                            <img [src]="imagePathPrefix + '/cart_indicator/60/50/' + item.productDetail.imagePath" [alt]="item.productDetail.name">
                        </picture>
                    </a>

                    <a [routerLink]="['/v/' + item.productDetail.id + '/' +  item.productDetail.seoUrl]" class="mini-cart-products__title">
                        {{item.productDetail.name}}
                    </a>

                    <div class="mini-cart-products__amount" [innerHTML]="(item.count| number:decimalPattern) + ' ' + item.productDetail?.unit.shortCut">
                    </div>

                    <div class="mini-cart-products__price">
                        <div class="prices">
                            <div class="prices__item prices__item--main">
                               <cmp-price [price]="item.count * item.productDetail.priceWithVAT"></cmp-price> {{sen['with-vat']}}
                            </div>

                            <div class="prices__item prices__item--base">
                                <cmp-price [price]="item.count * item.productDetail.priceWithoutVAT"></cmp-price> {{sen['without-vat']}}
                            </div>
                        </div>
                    </div>

                    <div class="mini-cart-products__remove">
                        <div class="icon" (click)="removeFromCart(item.id);$event.stopPropagation()">
                            <svg>
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cross-circle" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div *ngFor="let item of cartSvc?.coupons" class="mini-cart-products__item mini-cart-products__item--coupon">
                    <a class="mini-cart-products__image">
                        %
                    </a>

                    <div class="mini-cart-products__title">
                        {{item.couponDetail.name}} '{{item.couponDetail.code}}'
                    </div>

                    <div class="mini-cart-products__amount">
                        1
                    </div>

                    <div class="mini-cart-products__price">
                        <div class="prices">
                            <div class="prices__item prices__item--main">
                                <cmp-price [price]="-item.couponDetail.couponImpactOnOrderPrice"></cmp-price> {{sen['with-vat']}}
                            </div>
                        </div>
                    </div>

                    <div class="mini-cart-products__remove">
                        <div class="icon" (click)="removeFromCart(item.id); $event.stopPropagation()">
                            <svg>
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cross-circle" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mini-cart-products__actions">
                <a [routerLink]="['/kosik/obsah']" class="btn btn--red btn--large btn--radius-left">
                    {{sen['cart-indicator-cart']}}

                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-right" />
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
