<div class="header-options" [ngClass]="{'header-options--active': isCultureParentActive}" (click)="toggleCulture()">
    <div class="header-options__icon">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#globe"/>
            </svg>
        </div>
    </div>

    <div class="header-options__text">
        <strong>{{sen[selectedCulture.translationKey]}}</strong> {{seSvc.settings.currencyCodes[seSvc.currencyId]}}
        ({{seSvc.settings.currencies[seSvc.currencyId]}})
    </div>

    <div class="header-options-box">
        <div class="header-options-box__in">
            <div class="header-options-box__col">
                <div class="header-options-box__title">
                    {{sen['app-culture']}}
                </div>

                <div class="header-options-box__list">
                    <ng-container *ngFor="let item of seSvc.settings.cultures" >
                        <div class="header-options-box-item" [ngClass]="{'header-options-box-item--active': isCultureActive(item.code)}" (click)="setCulture(item.cultureId)">
                            <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/flag-' + item.code + '.png'" alt="{{sen[item.translationKey]}}">

                            {{sen[item.translationKey]}}
                        </div>
                    </ng-container>

                </div>
            </div>

            <div class="header-options-box__col">
                <div class="header-options-box__title">
                    {{sen['app-currency']}}
                </div>

                <div class="header-options-box__list">
                    <ng-container *ngFor="let item of currencyOptions">
                        <div class="header-options-box-item" [ngClass]="{'header-options-box-item--active': isCurrencyActive(item)}" (click)="setCurrency(item)">
                            {{seSvc.settings.currencyCodes[item]}}
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</div>