
import {Component, ViewEncapsulation} from "@angular/core";
import {Culture, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";



@Component({
    selector: 'cmp-header-option',
    templateUrl: '../../tpl/header-option.html',
    //styleUrls: ['../../assets/styles/3-layout/menu.scss'],
    encapsulation: ViewEncapsulation.None
})

export class HeaderOptionComponent extends Translatable {
    currencyOptions: number[] = [3, 2];
    cultureOptions: Culture[];
    selectedCulture: Culture;
    isCultureParentActive: boolean = false;

    constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        super(dataSvc, seSvc);
        this.cultureOptions = seSvc.settings.cultures;
        this.selectedCulture = this.seSvc.culture;
    }
    setCurrency(id: number): void {
        this.seSvc.setCultureAndReload(true, this.seSvc.culture.cultureId, id);
    }
    setCulture(id: number): void {
        this.selectedCulture = this.cultureOptions.filter(c => c.cultureId == id)[0];
        this.seSvc.setCultureAndReload(true, id, this.seSvc.currencyId);
    }
    ngOnInit(): void {

    }

    toggleCulture(){
        this.isCultureParentActive = !this.isCultureParentActive;
    }

    isCultureActive(code: string){
        return this.selectedCulture && this.selectedCulture.code == code;
    }
    isCurrencyActive(code: number){
        return this.seSvc.currencyId == code;
    }
}
