import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AddressSelector, CompanySelector, UserContactSummarySelector, UserSelector} from "../modules/address/common";
import {take} from "rxjs/operators";
import {ErrorService} from "./error.service";
import {Observable, ReplaySubject} from "rxjs";
import {CredentialStorage} from "./credential-storage.service";
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {AuthInfo} from "../interfaces/general";

@Injectable()
export class UserService {

    loaded: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    //region public fields
    company: CompanySelector = null;
    invoiceAddress: AddressSelector = null;
    deliveryAddresses: Array<AddressSelector> = [];
    user: UserSelector = null;
    //endregion

    //region public properties
    get preferredDeliveryAddress(): AddressSelector {
        return this.deliveryAddresses?.find(da => da.isPreferred);
    }

    //endregion


    constructor(private http: HttpClient, private eSvc: ErrorService) {
        this.init();
    }

    private init(): void {
        this.reloadUserContactInfo();
    }

    reloadUserContactInfo(): void {
        if (CredentialStorage.userLoggedIn) {
            let url = `api/account/getCompleteUserInfo`;
            this.http.get<UserContactSummarySelector>(url)
                .pipe(take(1))
                .subscribe(
                    (res) => {
                        // console.log(res);
                        this.company = res.company;
                        this.invoiceAddress = res.invoiceAddress;
                        this.deliveryAddresses = res.deliveryAddresses;
                        this.user = res.user;
                        let ai: AuthInfo = CredentialStorage.authInfo;
                        if (ai) {
                            let displayName = this.user?.firstName + ' ' + this.user?.lastName;
                            ai.displayName = displayName ?? res?.company?.name ?? '';
                            CredentialStorage.authInfo = ai;
                        }
                        this.loaded.next(true);
                    },
                    (err) => {
                        this.eSvc.handleError({error: err, request: {url: url, method: 'get'}}, 'getUserContactInfo');
                        this.loaded.next(false);
                    }
                );
        }
    }
    public UserNameExistValidator(id: number): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const o = new Observable<ValidationErrors>((ob) => {
                let request: any = {
                    UserName: control.value,
                    UserId: id
                };

                this.http.post('api/user/user-name-exists-validation', request)
                    .pipe(take(1))
                    .subscribe((res: any) => {
                        if (res.Exists) {
                            ob.next({
                                exists: true
                            });
                        } else {
                            ob.next(null);
                        }
                        ob.complete();
                    });
            });

            return o;
        }
    }
}
