import {Component, ViewEncapsulation} from "@angular/core";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {HeaderInvoiceService} from "../services/header-invoice.service";
import {takeUntil} from "rxjs/operators";
import {IHeaderInvoiceResult} from "../commons/commons";

@Component({
    selector: 'cmp-header-invoice',
    templateUrl: './header-invoice.html',
    styleUrls: ['../../../assets/styles/3-layout/header/header-invoices.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderInvoiceComponent extends Translatable {

    public info: IHeaderInvoiceResult = null;

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        private invoiceSvc: HeaderInvoiceService
    ) {
        super(dataSvc, seSvc);
        this.GetData();
    }

    private GetData(): void {
        this.invoiceSvc.GetHeaderInvoiceInfo()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((r) => {
                this.info = r;
            });
    }

}