<div class="article-list">
    <div class="article-list__inner">
        <ng-container *ngFor="let article of articles">
            <a [routerLink]="article.seoUrl" class="article-item">
                <div class="article-item__image-wrapper">
                    <picture class="article-item__image" *ngIf="article.imagePath; else elseImg">
                        <source media="(max-width: 479px)" [srcset]="imagePathPrefix + '/article_list/479/200/' + article.imagePath" />
                        <source media="(min-width: 480px)" [srcset]="imagePathPrefix + '/article_list/140/140/' + article.imagePath" />

                        <img [src]="imagePathPrefix + '/article_list/140/140/' + article.imagePath" [alt]="article.imageTitle">
                    </picture>

                    <ng-template #elseImg>
                        <img [src]="imagePathPrefix  + '/article_list/140/140/notfound.jpg'" [title]="article.imageTitle" [alt]="article.imageTitle"  class="article-item__image"/>
                    </ng-template>
                </div>

                <div class="article-item__text-wrapper">
                    <div class="article-item__title">
                        {{article.name}}
                    </div>

                    <div class="article-item__text" [innerHTML]="article.annotation"></div>

                    <div class="article-item__more">
                        {{sen['article-show-news']}}
                    </div>
                </div>
            </a>
        </ng-container>
    </div>
</div>
