/**
 * manual reporting
 */
import {Injectable, Injector} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ErrorSelector} from "../interfaces/general";

@Injectable()
export class ErrorService {

    constructor (private injector: Injector) {
    }

    handleError(error: any, subject?: string): void {
        const http: HttpClient = <HttpClient>this.injector.get(HttpClient);
        let errorSelector: ErrorSelector;
        let tmpMessage = '', tmpStack = '';

        try {
            if (error.hasOwnProperty('message') || error.hasOwnProperty('stack')) {
                if (error.hasOwnProperty('message')) {
                    tmpMessage = error['message']
                }
                if (error.hasOwnProperty('stack')) {
                    tmpStack = error['stack']
                }
                errorSelector = {
                    message: `message: ${tmpMessage} | stack: ${tmpStack}`,
                    subject: subject || null
                }

            } else {
                errorSelector = {
                    message: JSON.stringify(error),
                    subject: subject || null
                }
            }
        } catch (e) {
            errorSelector = {
                message: error.toString(),
                subject: subject || null
            };
        }

        http.post<ErrorSelector>('api/error/report', errorSelector)
            .subscribe(() => {});
    }
}