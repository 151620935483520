import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ProductBoxComponent} from "./product-box.component";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {CustomPipesModule} from "../pipe/custom-pipes.module";
import {ProductAmountModule} from "../product-amount/product-amount.module";
import {PriceModule} from "../price/price.module";
import {WishListModule} from "../wish-list/wish-list.module";
import {AvailabilityModule} from "../availability/availability.module";
import {ProductSelectorToProductBoxPipe} from './pipes/product-selector-to-product-box.pipe';
import {WishListItemToProductBoxPipe} from './pipes/wish-list-item-to-product-box.pipe';
import {MiniProductBoxComponent} from "./mini-product-box.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ImageCacheBustModule,
        ReactiveFormsModule,
        ValidationModule,
        CustomPipesModule,
        ProductAmountModule,
        PriceModule,
        WishListModule,
        AvailabilityModule
    ],
    declarations: [
        ProductBoxComponent,
        ProductSelectorToProductBoxPipe,
        WishListItemToProductBoxPipe,
        MiniProductBoxComponent
    ],
    exports: [
        ProductBoxComponent,
        ProductSelectorToProductBoxPipe,
        WishListItemToProductBoxPipe,
        MiniProductBoxComponent
    ]
})

export class ProductBoxModule {
}
