import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {CartItemSelectorBrief} from "../cart/common";
import {CartService} from "../cart/cart.service";
import {CompareProductsService} from "../../services/compare-products.service";
import {takeUntil} from "rxjs/operators";
import {IProductBoxModel} from "../product/common";
import {PageStateService} from "../../services/page-state.service";
import {AvailabilityService} from "../availability/services/availability.service";
import {IProductAvailability} from "../availability/commons/commons";

declare let $: any;

@Component({
    selector: 'cmp-product-box',
    templateUrl: '../../tpl/product-box.html',
    styleUrls: ['../../assets/styles/2-components/product-box.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductBoxComponent extends Translatable {

    @Input() showCloseButton: boolean = false;
    @Input() displayShoppingSection: boolean = true;
    @Output() closeButtonClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() countChanged: EventEmitter<number> = new EventEmitter<number>();

    private _item: IProductBoxModel;
    @Input('productboxproduct') set item(value: IProductBoxModel) {
        this._item = value;
    }

    get item(): IProductBoxModel {
        return this._item;
    }

    @Input() fullContent: boolean = true;
    @Input() showFlags: boolean = true;

    addingToCartInProgress: boolean = false;

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        private cartSvc: CartService,
        public compareSvc: CompareProductsService,
        private pageStateSvc: PageStateService,
        private availSvc: AvailabilityService
    ) {
        super(dataSvc, seSvc);

        this.cartSvc.cartContentChanged
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                () => {
                    this.addingToCartInProgress = false;
                }
            );
    }

    public get availability(): IProductAvailability {
        if (!this.IsVariant) {
            return this.availSvc.GetOnlineAvailability(this.item.code);
        } else {
            return null;
        }
    }

    buy(id: number, amount: number): void {
        let cart: CartItemSelectorBrief = {
            count: amount,
            productId: id
        };

        this.cartSvc.addToCart(cart);
    }

    changeCount(count: number): void {
        this.countChanged.emit(count);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    onClickDetail(): void {
        let topOffset = $(`.product-box-vk-${this.item.variantKey}`).offset().top;
        this.pageStateSvc.pageTopOffset = topOffset;
    }

    onCloseButtonClicked() {
        this.closeButtonClicked.emit();
    }

    public get IsVariant(): boolean {
        return this.item.variantsCount > 1;
    }
}


