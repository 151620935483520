import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ProductCarouselComponent} from "./product-carousel.component";
import {ProductBoxModule} from "../product-box/product-box.module";
import {HelperComponentsModule} from "../helper-components/helper-components.module";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";

@NgModule({
    imports: [
        CommonModule,
        ProductBoxModule,
        HelperComponentsModule,
        ImageCacheBustModule
    ],
    declarations: [ProductCarouselComponent],
    exports: [ProductCarouselComponent]
})

export class ProductCarouselModule {}