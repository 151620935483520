import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SettingsService} from "../../../services/settings.service";
import {DataService} from "../../../services/data.service";
import {Translatable} from "../../../interfaces/general";
import {takeUntil} from "rxjs/operators";
import {PriceVatService} from "../services/price-vat";
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
registerLocaleData(localeCs, 'cs');
import localeEn from '@angular/common/locales/en';
registerLocaleData(localeEn, 'en');
export interface IPrice {
    WithVat: number;
    WithoutVat: number;
}

@Component({
    selector: 'cmp-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss']
})
export class PriceComponent extends Translatable implements OnInit, OnChanges {

    @Input() price: number | IPrice = null;
    decimalPattern: string = null;
    @Input() currencySymbol: string = null;
    @Input() appendText: boolean = false; // Priprcne se k tomu text s dph nebo bez dph
    @Input() showPriceInColumn: boolean = false;
    @Input() useSmallFont: boolean = false;
    public locale: string = 'cs';
    public withVat: boolean = true;
    public priceValue: number = null;

    constructor(
        seSvc: SettingsService,
        dataSvc: DataService,
        priceVatSvc: PriceVatService
    ) {
        super(dataSvc, seSvc);
        this.withVat = priceVatSvc.IsWithVat;

        priceVatSvc.OnChange
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((val) => {
                this.withVat = val;
                this.CreateValue();
                this.setDecimalPattern();
            });
    }


    private CreateValue(): void {
        if (this.price !== null && this.price !== undefined) {
            if (this.price instanceof Object) {
                this.priceValue = this.withVat ? this.price.WithVat : this.price.WithoutVat;
            } else {
                this.priceValue = this.price;
            }
        }
    }

    ngOnInit() {
        this.setDecimalPattern();
        this.locale = this.seSvc.currencyId == this.seSvc.sharedAppDefaults['CurrencyId'] ? 'cs':'en';
    }

    ngOnChanges(_changes: SimpleChanges): void {
        if (!this.currencySymbol) this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];



        this.CreateValue();
    }

    private setDecimalPattern() {
        if (this.currencySymbol === 'Kč'){
            this.decimalPattern = '1.2-2';
        } else {
            this.decimalPattern = '1.2-2';
        }
    }

    public get IsValid(): boolean {
        const price = parseFloat(<any>this.priceValue);
        const res = !isNaN(price) && price !== null && price !== undefined && price.toString() === this.priceValue.toString();
        return res;
    }

}
