<table>
    <thead>
    <tr>
        <td>{{sen['cookies-consent-name']}}</td>
        <td>{{sen['cookies-consent-vendor']}}</td>
        <td>{{sen['cookies-consent-purpose']}}</td>
        <td>{{sen['cookies-consent-expiration']}}</td>
        <td>{{sen['cookies-consent-type']}}</td>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let cookie of cookies">
        <td>{{cookie.name}}</td>
        <td>{{cookie.vendor}}</td>
        <td>{{cookie.description}}</td>
        <td>{{getCookieExpiration(cookie)}}</td>
        <td>{{getCookieType(cookie)}}</td>
    </tr>
    </tbody>
</table>