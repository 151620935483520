import {Injectable} from "@angular/core";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {HttpClient} from "@angular/common/http";
import {catchError, map, switchMap, take} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {CaptchaResponseModel} from "../modules/register-b2c/common";
import {ErrorService} from "./error.service";

export interface FlexCaptchaExecuteResult {
    success: boolean;
    score: number;
    secret: string;
}

@Injectable()
export class FlexCaptchaService {

    constructor(private recaptchaV3Service: ReCaptchaV3Service, private http: HttpClient, private errSvc: ErrorService) {
    }

    executeCaptcha(idToken: string, logToken: string): Observable<FlexCaptchaExecuteResult> {
        return this.recaptchaV3Service.execute(idToken)
            .pipe(
                switchMap((captchaResponse: string) => this.onCaptchaExecuted(captchaResponse, logToken)),
                take(1)
            )
    }

    private onCaptchaExecuted(captchaResponse: string, logToken: string): Observable<FlexCaptchaExecuteResult> {
        return this.http.post<CaptchaResponseModel>('api/captcha/verify', {UserResponse: captchaResponse})
            .pipe(
                catchError((err: any) => {
                    this.errSvc.handleError(err, logToken);
                    return of<FlexCaptchaExecuteResult>({success: false, score: 0, secret: null});
                }),
                map(res => {
                    let success;
                    if (res.success) {
                        if (res.score >= 0.1) {
                            success = true;
                        } else {
                            success = false;
                            this.errSvc.handleError(res, logToken);
                        }
                    } else {
                        success = false;
                        this.errSvc.handleError(res, logToken);
                    }
                    let result: FlexCaptchaExecuteResult = {
                        success: success,
                        score: res.score,
                        secret: res.secret
                    }
                    return result
                }),
                take(1)
            )
    }

}
