<div class="product-list product-list--box" id="{{productCarouselSource}}" *ngIf="viewType === CarouselViewType.ProductBox && items.length > 0">
    <ng-container *ngFor="let product of items; let ind = index; let lst = last">
        <cmp-product-box
                [fullContent]="fullContent"
                [showFlags]="showFlags"
                [productboxproduct]="product | ProductSelectorToProductBox"
        >
            <cmp-last *ngIf="lst" (initiated)="onLast()"></cmp-last>
        </cmp-product-box>
    </ng-container>
</div>

<div class="product-list product-list--box-mini" id="{{productCarouselSource}}" *ngIf="viewType === CarouselViewType.MiniProductBox && items.length > 0">
    <ng-container *ngFor="let product of items; let ind = index; let lst = last">
        <cmp-mini-product-box
                [fullContent]="fullContent"
                [showFlags]="showFlags"
                [productboxproduct]="product | ProductSelectorToProductBox"
        >
            <cmp-last *ngIf="lst" (initiated)="onLast()"></cmp-last>
        </cmp-mini-product-box>
    </ng-container>
</div>

<div class="product-list product-list--box-mini" id="{{productCarouselSource}}" *ngIf="viewType === CarouselViewType.IndexProductBox && items.length > 0">
    <ng-container *ngFor="let product of items; let ind = index; let lst = last">
        <cmp-mini-product-box
                [fullContent]="fullContent"
                [showFlags]="showFlags"
                [productboxproduct]="product | ProductSelectorToProductBox"
        >
            <cmp-last *ngIf="lst" (initiated)="onLast()"></cmp-last>
        </cmp-mini-product-box>
    </ng-container>
</div>

