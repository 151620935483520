<div class="advantages" [class]="classModificator ? 'advantages--'+classModificator : ''">
    <div class="advantages__in">
        <div class="advantages__heading" [innerHTML]="sen['shop-advantages--title']">
        </div>

        <div class="advantages__list">
            <div class="advantages__item">
                <div class="advantages__image">
                    <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/static/ico-advantage1.svg'" alt="Vše si můžete prohlédnout">
                </div>

                <div class="advantages__content">
                    <div class="advantages__title">
                        {{sen['shop-advantages--item1--title']}}
                    </div>

                    <div class="advantages__text" [innerHTML]="sen['shop-advantages--item1--description']">
                    </div>
                </div>
            </div>

            <div class="advantages__item">
                <div class="advantages__image">
                    <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/static/ico-advantage2.svg'" alt="Nejsme překupníci">
                </div>

                <div class="advantages__content">
                    <div class="advantages__title">
                        {{sen['shop-advantages--item2--title']}}
                    </div>

                    <div class="advantages__text" [innerHTML]="sen['shop-advantages--item2--description']">
                    </div>
                </div>
            </div>

            <div class="advantages__item">
                <div class="advantages__image">
                    <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/static/ico-advantage3.svg'" alt="Odborníci na slovo vzatí">
                </div>

                <div class="advantages__content">
                    <div class="advantages__title">
                        {{sen['shop-advantages--item3--title']}}
                    </div>

                    <div class="advantages__text" [innerHTML]="sen['shop-advantages--item3--description']">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>