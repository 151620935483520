import {Observable} from "rxjs";
import {finalize} from "rxjs/operators";

export class ObjectCache<T, D> {

    private data: any = {};
    private locker: any = {};

    private queue: D[] = [];

    public constructor(
        private dataSource: IObjectCacheRepository<T, D>
    ) {
    }

    public Get(id: D): T {

        let item = this.data[id];

        if (item) {
            return item;
        } else {
            if (this.locker[id]) {
                return null;
            } else {
                this.locker[id] = true;

                if (this.queue.length === 0) {
                    setTimeout(() => {

                        const queue = [...this.queue];
                        this.dataSource.GetByIds(queue)
                            .pipe(finalize(() => {
                                this.locker[id] = false;
                            }))
                            .subscribe(res => {
                                queue.forEach((item) => {
                                    const val = res.find(x => x.Id === item);
                                    this.data[item] = (val && val.Item) || {};
                                });
                                this.queue = [];
                                this.locker = {};
                            });

                    });
                }

                if (!this.queue.find(x => x === id)) {
                    this.queue.push(id);
                }
            }
        }

        return null;
    }

    public Clear(): void {
        this.data = {};
    }

}

export interface IObjectCacheRepository<T, D> {
    GetByIds(ids: D[]): Observable<IObjectCacheResultItem<T, D>[]>
}

export interface IObjectCacheResultItem<T, D> {
    Id: D;
    Item: T;
}