import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {HeaderOptionComponent} from "./header-option.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [HeaderOptionComponent],
    exports: [HeaderOptionComponent]
})

export class HeaderOptionModule {
}
