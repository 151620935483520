export class GeneralDialogConfig<D = any> {
    data: D; //can be null
    cssClassModifier: string; //can be null
    isClosable: boolean = true; //determines if closing cross is displayed and if an overlay click closes the modal
    title: string; //can be null

    public static get Empty(): GeneralDialogConfig {
        const ret: GeneralDialogConfig = {
            data: null,
            cssClassModifier: null,
            isClosable: true,
            title: null
        };
        return ret;
    }
}
