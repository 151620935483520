import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WishListButtonComponent} from './wish-list-button/wish-list-button.component';

@NgModule({
    declarations: [
        WishListButtonComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        WishListButtonComponent
    ]
})
export class WishListModule {
}
