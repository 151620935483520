<div class="cookies-panel" *ngIf="showWarning && !isCookiesModalVisible">
    <div class="cookies-panel__header">
        <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/img-cookies.svg'" alt="Cookies">

        <div class="cookies-panel__title heading-h1">
            {{sen['cookies-panel--title']}}
        </div>
    </div>

    <div class="cookies-panel__text" [innerHTML]="ccSvc?.cookiesConsentTexts?.consentText">
        <!--div [innerHTML]="sen['cookies-panel--text1']"></div>
        <div [innerHTML]="sen['cookies-panel--text2']"></div>
        <div>
            {{sen['cookies-panel--text3']}} <a [routerLink]="[seSvc.settings.termsAndConditions[this.seSvc.culture.cultureId].gdprUrl]">{{sen['cookies-panel--text4']}}</a> {{sen['cookies-panel--text5']}}
        </div-->
    </div>

    <div class="cookies-panel__actions">
        <button class="btn btn--large btn--green" (click)="OnOkButtonClick()">
            {{sen['cookies-panel--button-ok']}}
        </button>

        <button class="btn btn--large btn--gray" (click)="OnCustomButtonClick()">
            {{sen['cookies-panel--button-custom']}}
        </button>
    </div>
</div>