import {Directive, ElementRef, Input} from "@angular/core";
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({selector: '[fixed]'})
export class FixedClassDirective {

    @Input() fixedClass: string | string[];
    @Input() ratio: number = 1;
    @Input() offset: number = 0;
    @Input() offsetSelector: string;

    private ngUnsubscribe = new Subject();

    constructor(private elRef: ElementRef) {

        fromEvent(window, 'scroll')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                if (typeof (this.fixedClass) == 'string') {
                    this.elRef.nativeElement.classList.toggle(this.fixedClass, window.scrollY > this.getFullOffset());
                    return;
                }

                if (Array.isArray(this.fixedClass)) {
                    for (let item of this.fixedClass) {
                        this.elRef.nativeElement.classList.toggle(item, window.scrollY > this.getFullOffset());
                    }
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getFullOffset(): number {
        let offset = 0;
        if (this.offsetSelector) {
            let elems = document.querySelectorAll(this.offsetSelector);
            for (let i = 0; i < elems.length; i++) {
                offset += elems[i].scrollHeight;
            }
        }
        return offset + this.offset;
    }
}