import {NgModule} from '@angular/core';
import {CropHtml} from "./cropHtml";
import {StockAvailability} from "./stockAvailability";
import {DecimalPipe} from "@angular/common";
import {WebpPipe} from "./webp.pipe";
import { SearchExpressionHighlightPipe } from './search-expression-highlight.pipe';

@NgModule({
    declarations: [
        CropHtml,
        StockAvailability,
        WebpPipe,
        SearchExpressionHighlightPipe
    ],
    exports: [
        CropHtml,
        StockAvailability,
        WebpPipe,
        SearchExpressionHighlightPipe
    ],
    providers: [
        DecimalPipe
    ]
})

export class CustomPipesModule {
}
