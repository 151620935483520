import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Translatable} from "../../../interfaces/general";
import {SettingsService} from "../../../services/settings.service";
import {DataService} from "../../../services/data.service";
import {IProductAmountSettings} from "../interfaces/IProductAmountSettings";
import {IProductAmountConfirm} from "../interfaces/IProductAmountConfirm";
import {Numeric} from "../../../helpers/numeric.helper";
import {MathUtils} from "../../../helpers/MathUtils";

@Component({
    selector: 'cmp-product-amount',
    templateUrl: './product-amount.component.html',
    styleUrls: ['../../../assets/styles/2-components/amount.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductAmountComponent extends Translatable implements OnInit, OnChanges {

    @Input() classModificator: string;
    @Input() isDisabled: boolean = false;
    @Input() isLarge: boolean = false;

    @Input() data: IProductAmountSettings = null;
    @Output() confirmed: EventEmitter<IProductAmountConfirm> = new EventEmitter<IProductAmountConfirm>();

    @Input() amount: number = null;
    @Output() amountChange: EventEmitter<number> = new EventEmitter<number>();

    public form: FormGroup = null;

    constructor(
        seSvc: SettingsService,
        dataSvc: DataService,
        private fb: FormBuilder
    ) {
        super(dataSvc, seSvc);

        this.form = this.fb.group({
            quantity: [1, [Validators.required, Validators.pattern(this.seSvc.settings.validationPatterns.decimalNumber), Validators.maxLength(5)]]
        });
    }

    ngOnInit() {  
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && changes['data'].currentValue) {
            this.form.patchValue({
                quantity: this.data.MinQuantity
            });
        }
        else if (changes['data'] && changes['data'].currentValue === null) {
            this.form.patchValue({
                quantity: 1
            });
        }
        if (changes['amount'] && changes['amount'].firstChange && Numeric.isNumber(changes['amount'].currentValue)) {
            this.form.patchValue({
                quantity: this.amount
            });
        }
    }

    public SubtractAmount(elem: HTMLInputElement): void {
        const step: number = this.data.Factor || 1;
        const val: number = parseFloat((<HTMLInputElement>elem).value.replace(',', '.'));
        let newVal = null;
        if (Number.isNaN(val)) {
            newVal = this.data.MinQuantity;
        }
        else {
            newVal = val - step;
            if (!MathUtils.IsMultiple(newVal, step)) {
                newVal = val - val % step;
            }
            newVal = parseFloat(newVal.toFixed(5));
        }

        if (newVal < this.data.MinQuantity)
            newVal = this.data.MinQuantity;

        if (this.Amount !== newVal) {
            this.form.patchValue({
                quantity: newVal
            });

            if (this.IsValid)
                this.amountChange.emit(this.Amount);
        }
    }

    public AddAmount(elem: HTMLInputElement): void {
        const step: number = this.data.Factor || 1;
        let val: number = parseFloat((<HTMLInputElement>elem).value.replace(',', '.'));
        let newVal = null;
        if (Number.isNaN(val)) {
            newVal = this.data.MinQuantity;
        }
        else {
            newVal = val + step;
            if (!MathUtils.IsMultiple(newVal, step)) {
                newVal = newVal - newVal % step;
            }
            newVal = parseFloat(newVal.toFixed(5));
        }
        this.form.patchValue({
            quantity: newVal
        });

        if (this.IsValid)
            this.amountChange.emit(this.Amount);
    }

    public GetMinAmount(): number {
        if (!this.data || !this.data.MinQuantity) return 0;
        return this.data.MinQuantity;
    }

    public GetMaxAmount(): number {
        if (!this.data || !this.data.MaxQuantity) return Number.MAX_VALUE;
        return this.data.MaxQuantity;
    }

    public IsMultiple(): boolean {
        if (!this.data || !this.data.Factor)
            return true;

        const res = MathUtils.IsMultiple(this.Amount, this.data.Factor);
        return res;
    }

    public KeyUp(evt: KeyboardEvent): void {
        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            if (this.form.valid) {
                this.confirmed.emit(this.GetAmountConfirm());
            }
        }
    }

    public GetAmountConfirm(): IProductAmountConfirm {
        return this.form.valid ? {
            Amount: this.Amount
        } : null;
    }

    public get Amount(): number {
        try {
            const value = this.form.get('quantity').value;
            if (value) {
                const parsedValue = parseFloat(value.toString().replace(',', '.'));
                if (!Number.isNaN(parsedValue)) {
                    return parsedValue;
                }
            }
        }
        catch {
            return null;
        }
        return null;
    }

    public InputChange(): void {
        if (this.IsValid)
            this.amountChange.emit(this.Amount);
    }

    public get IsValid(): boolean {
        return this.form.valid && this.GetMinAmount() <= this.Amount && this.Amount <= this.GetMaxAmount() && this.IsMultiple();
    }
}
