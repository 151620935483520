import {Component, ViewEncapsulation} from "@angular/core";
import {LoginEmitterMessage, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GeneralDialogRef} from "../general-dialog/common";
import {DigestService, StandardLogin} from "../../services/digest.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {FlexCaptchaService} from "../../services/flex-captcha.service";

@Component({
    templateUrl: '../../tpl/login-dialog.html',
    styleUrls: ['../../assets/styles/2-components/modal-window/modal-login.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LoginDialogComponent extends Translatable {

    userForm: FormGroup;
    mailPattern: RegExp;
    activated: boolean = false;
    isError: boolean = false;
    captchaFailed: boolean = false;
    private ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, seSvc: SettingsService, private digestSvc: DigestService,
                private fb: FormBuilder, private dialogRef: GeneralDialogRef, private fcSvc: FlexCaptchaService) {
        super(dataSvc, seSvc);

        this.mailPattern = this.seSvc.settings.validationPatterns.email;

        this.digestSvc.loginStatus
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: LoginEmitterMessage) => {
                if (res && res.data && res.data.error && res.data.error.toString() === 'NOT_ACTIVATED') {
                    this.activated = false;
                    this.isError = true;
                }
                else if (res && res.type == this.digestSvc.errorType && res.message == this.digestSvc.badCredentialsMessage) {
                    this.activated = true;
                    this.isError = true;
                }
            });
    }

    ngOnInit(): void {
        this.createForm();
    }

    executeCaptcha(): void {
        this.fcSvc.executeCaptcha('login', 'login-dialog.component.onCaptchaExecuted')
            .subscribe(res => {
                this.captchaFailed = !res.success;
                if (res.success) {
                    this.login(res.secret);

                }
            })
    }

    login(secret: string) {
        // this.digestSvc.login(this.userForm.get('userName').value, this.userForm.get('password').value, secret);
        this.digestSvc.login(
            new StandardLogin(
                this.userForm.get('userName').value,
                this.userForm.get('password').value,
                secret
            )
        );
    }

    private createForm(): void {
        this.userForm = this.fb.group({
            userName: ['', [Validators.required, Validators.minLength(4), Validators.pattern(this.mailPattern)]],
            password: ['', [Validators.required, Validators.minLength(4)]]
        })
    }

    onKey(evt: KeyboardEvent): void {
        this.isError = false;
        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            if (this.userForm.valid) {
                this.executeCaptcha();
            }
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
