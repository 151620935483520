import {Component, OnInit} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";

@Component({
    selector: 'cmp-debug-box',
    templateUrl: './debug-box.component.html',
    styleUrls: ['./debug-box.component.scss']
})
export class DebugBoxComponent extends Translatable implements OnInit {

    public isVisible: boolean = false;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

    public OnToggleButtonClick(): void {
        this.isVisible = !this.isVisible;
    }

}
