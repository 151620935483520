import {Pipe, PipeTransform} from '@angular/core';
import {IWishListItem} from "../../account/common";
import {IProductBoxModel} from "../../product/common";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
    name: 'WishListItemToProductBox'
})
export class WishListItemToProductBoxPipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    transform(data: IWishListItem): IProductBoxModel {
        const res: IProductBoxModel = {
            accessLevel: data.variant.accessLevel,
            discount: <number>data.variant.discount,
            variantId: data.variant.id,
            name: data.variant.name,
            annotation: data.variant.annotation,
            description: this.sanitizer.bypassSecurityTrustHtml(<string>data.variant.description),
            isNew: data.variant.isNew,
            isAction: data.variant.isAction,
            isSellOut: data.variant.isSellOut,
            isRecommended: data.variant.isRecommended,
            images: null,
            rating: null,
            code: data.variant.code,
            ean: data.variant.ean,
            producerName: data.variant.producerName,
            basePrice: data.variant.basePrice,
            priceWithVAT: data.variant.priceWithVAT,
            priceWithVatRange: null,
            priceWithoutVatRange: null,
            priceWithoutVAT: data.variant.priceWithoutVAT,
            variantKey: data.variant.variantKey,
            unit: data.variant.unit,
            minQtyOrder: data.variant.minQtyOrder,
            maxQtyOrder: data.variant.maxQtyOrder,
            factor: data.variant.factor,
            qtyInStock: data.variant.qtyInStock,
            availability: data.variant.availability,
            urlToDisplay: `/v/${data.variant.id}/${data.variant.seoUrl}/`,
            imagePath: data.variant.imagePath,
            imageTitle: data.variant.imageTitle,
            isComparable: false,
            variantsCount: 1,
            discontinued: data.variant.discontinued,
            confirmed: data.variant.confirmed,
            count: data.count
        };

        return res;
    }

}
