<cmp-js-scripts></cmp-js-scripts>

<cmp-notify-stripe></cmp-notify-stripe>

<div class="top-bar">
    <div class="container">
        <div class="top-bar__in">
            <img [src]="seSvc.settings.newImageServerUrl + '/logo/135/24' + seSvc.brand.logoFirst" alt="3M" class=""/>
            <cmp-article-main-menu></cmp-article-main-menu>

            <div class="top-bar__right">
                <cmp-header-invoice *ngIf="userLoggedIn"></cmp-header-invoice>

                <cmp-login [classModificator]="'desktop'"></cmp-login>

                <cmp-header-option></cmp-header-option>
            </div>
        </div>
    </div>
</div>
<!--[fixedClass2]="'header--smaller'"-->
<header class="header" fixed fixed2 [fixedClass]="'header--sticky'" [offsetSelector]="'.notify-stripe, .top-bar'" [offsetSelector2]="'.notify-stripe, .top-bar, .header'">
    <div class="header__wrap">
        <div class="container">
        <div class="header__inner">
            <div class="responsive-menu" (click)="toggleMenu($event)">
                <div class="responsive-menu__line"></div>
                <div class="responsive-menu__line"></div>
                <div class="responsive-menu__line"></div>
            </div>

            <a [routerLink]="['/']" title="{{sen['shop-name']}}" class="logo">
                <img [src]="seSvc.settings.newImageServerUrl + '/logo/131/36' + seSvc.brand.logoSecond" alt="{{sen['shop-name']}}" class="logo__image"/>

                <div class="logo__motto" [innerHTML]="sen['logo-moto']">
                </div>
            </a>

            <div class="header__inner__right">
                <cmp-flex-suggest #suggest></cmp-flex-suggest>

                <a href="{{'tel: ' + sen['index-phone-number']}}" class="header-contact header-contact--desktop">
                    <div class="header-contact__image">
                        <div class="icon">
                            <svg>
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#phone"/>
                            </svg>
                        </div>
                    </div>

                    <div class="header-contact__content">
                        <div class="header-contact__text">
                            {{sen['index-opening-hours']}}
                        </div>

                        <div class="header-contact__value">
                            {{sen['index-phone-number']}}
                        </div>
                    </div>
                </a>

                <cmp-login [classModificator]="'mobile'"></cmp-login>

                <cmp-cart-indicator></cmp-cart-indicator>
            </div>
        </div>

        <div class="header__bottom">
            <cmp-menu></cmp-menu>
        </div>
    </div>
    </div>
</header>

<router-outlet></router-outlet>

<cmp-newsletter-registration *ngIf="dataSvc.displayNewsletter"></cmp-newsletter-registration>

<footer class="footer">
    <div class="container">
        <cmp-footer-columns-list [articles]="articles"></cmp-footer-columns-list>

        <div class="copyright">
            <div class="copyright__text">
                {{seSvc.copyright?.text}}
            </div>

            <div class="copyright__logos">
                <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/img-copyright-logos.png'" alt="Delivery payments logos"/>
            </div>

            <div class="copyright__right">
                <div class="copyright__cookies">
                    <a (click)="ccSvc.showWarning=true" href="javascript:void(0)">
                        {{sen['cookies-consent-setting']}}
                    </a>
                </div>

                <div class="copyright__up" (click)="scrollUp()">
                    {{sen['index-scroll-up']}}

                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-up"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- preloader -->
<cmp-preloader [loading]="dataSvc.dataLoading"></cmp-preloader>

<!-- Cookie usage warning -->
<cmp-cookie-warning [DelayToShow]="3000" [ExpireInHours]="720" *ngIf="false"></cmp-cookie-warning>

<!-- Cookie consent new and fancy yet modern -->
<!--<cmp-cookie-consent></cmp-cookie-consent>-->

<cmp-cookies-panel></cmp-cookies-panel>