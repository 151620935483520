<div class="amount" [ngClass]="classModificator ? 'amount--'+classModificator : ''" [formGroup]="form" (click)="$event.stopPropagation(); $event.preventDefault()">
    <div class="amount__in">
        <button class="amount__controller amount__controller--minus" (click)="SubtractAmount(amountInput)" [disabled]="isDisabled">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#minus"/>
                </svg>
            </div>
        </button>

        <input #amountInput type="text" class="amount__input" formControlName="quantity" (keyup)="KeyUp($event)" (change)="InputChange()" [attr.disabled]="isDisabled ? 'isDisabled' : null"/>

        <button class="amount__controller amount__controller--plus" (click)="AddAmount(amountInput)" [disabled]="isDisabled">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#plus"/>
                </svg>
            </div>
        </button>

    </div>

    <div class="amount__unit">
        {{data?.Unit}}
    </div>

    <div class="amount-validation">
        <cmp-val-msg [inAmount]="true" *ngIf="form.invalid && form.dirty" [msg]="sen['product-wrong-amount']"></cmp-val-msg>
        <cmp-val-msg [inAmount]="true" *ngIf="Amount < GetMinAmount()" [msg]="sen['detail-qty-not-satisfied'] + ' ' + GetMinAmount() + ' ' + (this.data?.Unit || '')"></cmp-val-msg>
        <cmp-val-msg [inAmount]="true" *ngIf="Amount > GetMaxAmount()" [msg]="sen['product-qty-exceeded'] + ' ' + GetMaxAmount() + ' ' + (this.data?.Unit || '')"></cmp-val-msg>
        <cmp-val-msg [inAmount]="true" *ngIf="!IsMultiple()" [msg]="sen['product-qty-multiple'] + ' ' + this.data?.Factor"></cmp-val-msg>
    </div>
</div>