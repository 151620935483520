import {Component, ElementRef, HostListener, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {WishListService} from "../../../services/wish-list-service";
import {take} from "rxjs/operators";

declare let $: any;

@Component({
    selector: 'cmp-wish-list-button',
    templateUrl: './wish-list-button.component.html',
    styleUrls: ['../../../assets/styles/2-components/wishlist.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WishListButtonComponent extends Translatable implements OnInit {

    @Input() variantId: number = null;
    @Input() type: string;

    wishListExpanded: boolean = false;
    wishListAddMode: boolean;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        public wishListSvc: WishListService,
        private elRef: ElementRef
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

    wishListToggle(_event: MouseEvent): void {
        this.wishListExpanded = !this.wishListExpanded;
        if (this.wishListExpanded) {
            this.openWishLists();
        } else {
            this.closeWishLists();
        }
    }

    closeWishLists() {
        $(this.elRef.nativeElement).find('.js-wishlist').slideUp(250);
        this.wishListExpanded = false;
        this.wishListAddMode = false;
    }

    openWishLists() {
        $(this.elRef.nativeElement).find('.js-wishlist').slideDown(250);
        this.wishListExpanded = true;
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(ev: MouseEvent) {
        const $this = $(this.elRef.nativeElement);
        const $target = $(ev.target);

        if ($target.closest($this).length === 0) {
            this.closeWishLists();
        }
    }

    initCreateWishList(): void {
        this.wishListAddMode = true;
        let tim = setTimeout(() => {
            $(this.elRef.nativeElement).find('.js-wl-input').focus();
            clearTimeout(tim);
        }, 0)
    }

    wishListKeyUp(evt: KeyboardEvent, name: string): void {

        if (!name || !name.length) return;

        switch (evt.code) {
            case 'Enter':
                this.createWishList(name);
                break;
            case 'NumpadEnter':
                this.createWishList(name);
                break;
            case 'Escape':
                this.wishListAddMode = false;
                break;
        }
    }

    createWishList(name: string): void {
        this.wishListAddMode = false;
        this.wishListSvc.createNewList(name)
            .pipe(take(1))
            .subscribe((hash) => {
                this.onItemToWishListClicked(hash);
            })
        ;
    }

    onItemToWishListClicked(wishListHash: string): void {
        if (this.isOnList(wishListHash)) {
            this.wishListSvc.removeFromWishList(wishListHash, this.variantId);
        } else {
            this.wishListSvc.addItemToWishList(wishListHash, this.variantId);
        }
    }

    isOnList(listHash: string): boolean {
        return this.wishListSvc.isOnList(listHash, this.variantId);
    }

    isOnAnyList(): boolean {
        if (!this.wishListSvc.wishLists || !this.wishListSvc.wishLists.length) {
            return false;
        }

        for (let i = 0; i < this.wishListSvc.wishLists.length; i++) {
            if (this.isOnList(this.wishListSvc.wishLists[i].hash)) {
                return true;
            }
        }

        return false;
    }

}
