import {Pipe, PipeTransform} from "@angular/core";
import {IProductAmountSettings} from "./interfaces/IProductAmountSettings";
import { CartItemSelectorFull } from "../cart/common";
import {ProductAmountCartAdapter} from "../../helpers/ProductAmountCartAdapter";


@Pipe({name: 'ProductAmountCart'})
export class ProductAmountCartPipe implements PipeTransform {

    transform(product: CartItemSelectorFull): IProductAmountSettings {
        if (!product)
            return null;

        return new ProductAmountCartAdapter(<CartItemSelectorFull>product);
    }
}