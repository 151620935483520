import {Injectable} from '@angular/core';
import {loadFromLocalStorage, saveToLocalStorage} from "../../../helpers/cookie.helper";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PriceVatService {

    private static stateToken: string = 'price-vat-state-token';

    private isWithVat: boolean = true;

    public OnChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor() {
        this.RestoreState();
    }

    private SaveState(): void {
        saveToLocalStorage(PriceVatService.stateToken, this.isWithVat);
    }

    private RestoreState(): void {
        try {
            this.isWithVat = loadFromLocalStorage(PriceVatService.stateToken);
            if (this.isWithVat == null) {
                this.isWithVat = true;
            }
        } catch {
            this.isWithVat = true;
        }
        this.OnChange.next(this.isWithVat);
    }

    public get IsWithVat(): boolean {
        return this.isWithVat;
    }

    public set IsWithVat(value: boolean) {
        this.isWithVat = value;
        this.OnChange.next(this.isWithVat);
        this.SaveState();
    }
}