import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'webp'})
export class WebpPipe implements PipeTransform {

    transform(value: string): any {
        if (!value) return value;

        let ext = value.match(/\.[^.]*$/)[0];
        return value.replace(ext, '.webp');
    }

}
