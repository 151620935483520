/**
 * Main application module
 * WARNING:
 * Since this module is loaded first, keep it as small as possible!!!
 * do not add any route-specific features here, look into "app/modules/app/app.routes.ts"
 * how this should be done correctly
 */
import {APP_INITIALIZER, enableProdMode, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AppRoutingWithProviders} from "./app.routes";
import {DataService} from "../../services/data.service";
import {DigestService} from "../../services/digest.service";
import {ErrorService} from "../../services/error.service";
import {GetLocaleFactory, SettingsService} from "../../services/settings.service";
import {LoginModule} from "../login/login.module";
import {ArticleService} from "../articles/article.service";
import {CartService} from "../cart/cart.service";
import {SharedAppSettings, SharedCategorySettings, SharedProductSettings} from "../../shared-settings/shared-settings";
import {CartIndicatorModule} from "../cart-indicator/cart-indicator.module";
import {PreAuthService} from "../pre-auth/pre-auth.service";
import {HelperComponentsModule} from "../helper-components/helper-components.module";
import {ArticleModule} from "../articles/article.module";
import {CartChangeModalModule} from "../cart-modal/cart-change-modal.module";
import {BreadcrumbsModule} from "../breadcrumbs/breadcrumbs.module";
import {PreloaderModule} from "../preloader/preloader.module";
import {WishListService} from "../../services/wish-list-service";
import {CompareProductsService} from "../../services/compare-products.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MainInterceptor} from "../../interceptors/main.interceptor";
import {IntegrationService} from "../../interceptors/integration.service";
import localeCs from '@angular/common/locales/cs';
import localeSk from '@angular/common/locales/sk';
import localeEn from '@angular/common/locales/en';
import {registerLocaleData} from "@angular/common";
import {CookieWarningModule} from "../cookie-warning/cookie-warning.module";
import {GlobalErrorHandler} from "../../services/global-error-handler.service";
import {CredentialStorage} from "../../services/credential-storage.service";
import {NotifyStripeModule} from "../notify-stripe/notify-stripe.module";
import {SearchService} from "../../services/search.service";
import {UiDirectivesModule} from "../../directives/ui-directives.module";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {FlexSuggestModule} from "../flex-suggest/flex-suggest.module";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";
import {NewsLetterRegistrationModule} from "../newsletters/newsletter-registration.module";
import {MenuModule} from "../menu/menu.module";
import {MenuService} from "../../services/menu.service";
import {CategoryService} from "../../services/category.service";
import {SettingsAboutusModule} from "../settings/settings-aboutus.module";
import {SettingsSocialModule} from "../settings-social/settings-social.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LuigiSuggestModule} from "../luigi-suggest/luigi-suggest.module";
import {CustomPipesModule} from "../pipe/custom-pipes.module";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from "../../environments/environment";
import {CartTokenService} from "../cart/cart-token.service";
import {DeliveryPaymentService} from "../delivery-payment/delivery-payment.service";
import {UserService} from "../../services/user.service";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {CookiesConsentModule} from "../cookies-consent/cookies-consent.module";
import {CookiesConsentService} from "../cookies-consent/cookies-consent.service";
import {AccountMenuItemsService} from "../account-menu/account-menu.service";
import {FlexCaptchaService} from "../../services/flex-captcha.service";
import {AskExpertModule} from "../ask-expert/ask-expert.module";
import {ShopAdvantagesModule} from "../shop-advantages/shop-advantages.module";
import {HeaderInvoiceModule} from "../header-invoices/header-invoice.module";
import {FooterModule} from "../footer/footer.module";
import {HeaderOptionModule} from "../header-option/header-option.module";
import {FixedClassModule} from '../../directives/fixed-class/fixed-class.module';

registerLocaleData(localeCs, 'cs-CZ');
registerLocaleData(localeSk, 'sk-SK');
registerLocaleData(localeEn, 'en-US');

export function AppInitFactory(seSvc: SettingsService, ccSvc: CookiesConsentService) {
    ccSvc.setStorageHooks();
    return () => seSvc.getCartTokens()
}

enableProdMode();

@NgModule({
    imports: [
        BreadcrumbsModule,
        BrowserModule,
        BrowserAnimationsModule,
        ArticleModule,
        CartIndicatorModule,
        CartChangeModalModule,
        HelperComponentsModule,
        HttpClientModule,
        ImageCacheBustModule,
        LoginModule,
        PreloaderModule,
        AppRoutingWithProviders,
        CookieWarningModule,
        NotifyStripeModule,
        UiDirectivesModule,
        FlexSuggestModule,
        GeneralDialogModule,
        NewsLetterRegistrationModule,
        MenuModule,
        SettingsAboutusModule,
        SettingsSocialModule,
        CustomPipesModule,
        RecaptchaV3Module,
        ServiceWorkerModule.register('ngsw-worker.js', {
            scope: '/',
            enabled: environment.production && environment.isPwa,
            registrationStrategy: 'registerImmediately'
        }),
        CookiesConsentModule,
        AskExpertModule,
        ShopAdvantagesModule,
        HeaderInvoiceModule,
        FooterModule,
        MenuModule,
        HeaderOptionModule,
        FixedClassModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        UserService,
        AccountMenuItemsService,
        DeliveryPaymentService,
        LuigiSuggestModule,
        MenuService,
        SharedCategorySettings,
        SharedProductSettings,
        CategoryService,
        CredentialStorage,
        IntegrationService,
        ArticleService,
        CartService,
        CartTokenService,
        Title,
        DataService,
        DigestService,
        ErrorService,
        SearchService,
        CompareProductsService,
        /**
         * @description
         * If you need your pipes to respond to culture changes
         * you must configure LOCALE_ID provider via settings {@link SettingsService} GetLocaleFactory function
         */
        {
            provide: LOCALE_ID,
            deps: [SettingsService],
            useFactory: GetLocaleFactory
        },
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitFactory,
            deps: [SettingsService, CookiesConsentService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MainInterceptor,
            multi: true
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6Lfip6cUAAAAACoF3CDLnoTzYtu6NeU4RVJkfLJ6'
        },
        PreAuthService,
        SettingsService,
        SharedAppSettings,
        WishListService,
        CookiesConsentService,
        FlexCaptchaService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
