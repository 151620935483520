import {Component, Input, ViewEncapsulation} from "@angular/core";
import {ArticleBriefSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";

@Component({
    selector: 'cmp-footer-columns-list',
    templateUrl: '../../tpl/footer-columns-list.html',
    encapsulation: ViewEncapsulation.None
})
export class FooterColumnsListComponent extends Translatable{

    @Input() articles: Array<ArticleBriefSelector> = [];

    allowedArticleCodes: Array<string> = ['footer-col-1', 'footer-col-2', 'footer-col-3','footer-col-4','footer-col-5'];
    active:Array<string> =[];

    constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        super(dataSvc, seSvc);
    }
    articleByCode(code:string): ArticleBriefSelector{
        return this.articles?.find(aR=>aR?.code === code);
    }
    isActiveClass(code: string){
        return this.active && this.active.includes(code);
    }
    setColActiveClass(code: string){
        if(this.active){
            if(this.active.includes(code)){
                this.active = this.active.filter(x => x !== code);
            }
            else{
                this.active.push(code);
            }
        }
    }
}