<div class="cookie-warning" *ngIf="false">
    <div class="cookie-warning__message" *ngIf="showWarning"><span>{{sen['cookie-warning-text']}}</span>
        <button class="cookie-warning__confirm" (click)="cookieAccept()">
            {{sen['cookie-warning-accept']}}
        </button>
    </div>
</div>


<div class="cookie-warning">
    <div class="cookie-warning__container">
        <div class="cookie-warning__title">
            Tato webová stránka používá cookies
        </div>

        <div class="cookie-warning__text" >
            Jsme společnosti ze SKUPINY ČEZ. Na těchto stránkách fungují cookies, které naše společnosti využívají. Jednotlivé typy cookies a jejich dobu zpracování naleznete popsané níže v tabulce. Zvolte prosím Vámi preferovanou variantu. Pokud byste nás potřebovali ohledně výkonu vašich práv v souvislosti se zpracováním cookies kontaktovat, obraťte se prosím na společnost, jejíž stránky procházíte, nebo na našeho Pověřence pro ochranu osobních údajů na webové adrese gdpr.cez.cz. Pokud si myslíte, že s osobními údaji nenakládáme, jak bychom měli, máte možnost podat stížnost u Úřadu pro ochranu osobních údajů. Budeme však rádi, pokud se nejdříve obrátíte přímo na nás a budeme tak moct Váš požadavek obratem vyřešit.
        </div>

        <div class="cookie-warning__actions">
            <button class="cookie-warning__btn">
                Pouze nutné cookies
            </button>

            <button class="cookie-warning__btn">
                Povolit výběr
            </button>

            <button class="cookie-warning__btn cookie-warning__btn--primary">
                Povolit všechny cookies
            </button>
        </div>

        <div class="cookie-warning-bar">
            <div class="cookie-warning-bar__list">
                <div class="cookie-warning-bar__item">
                    <label for="volba1">
                        <input id="volba1" type="checkbox">

                        Nutné
                    </label>
                </div>

                <div class="cookie-warning-bar__item">
                    <label for="volba2">
                        <input id="volba2" type="checkbox">

                        Preferenční
                    </label>
                </div>

                <div class="cookie-warning-bar__item">
                    <label for="volba3">
                        <input id="volba3" type="checkbox">

                        Statistické
                    </label>
                </div>

                <div class="cookie-warning-bar__item">
                    <label for="volba4">
                        <input id="volba4" type="checkbox">

                        Marketingové
                    </label>
                </div>
            </div>

            <button class="cookie-warning-bar__settings">
                Nastavení
            </button>
        </div>

        <div class="cookie-warning-tabs">
            <div class="cookie-warning-tabs__head">
                <div class="cookie-warning-tabs__item cookie-warning-tabs__item--active">
                    Prohlášení o cookies
                </div>

                <div class="cookie-warning-tabs__item">
                    O aplikaci
                </div>
            </div>

            <div class="cookie-warning-tabs__body">
                <div class="cookie-warning-tabs__item cookie-warning-tabs__item--active">
                    <div class="cookie-warning-content">
                        <div class="cookie-warning-content__side">
                            <div class="cookie-warning-content__item cookie-warning-content__item--active">
                                Nutné (14)
                            </div>

                            <div class="cookie-warning-content__item">
                                Preferenční (5)
                            </div>

                            <div class="cookie-warning-content__item">
                                Statistické
                            </div>

                            <div class="cookie-warning-content__item">
                                Marketingové
                            </div>

                            <div class="cookie-warning-content__item">
                                Neklasifikované (6)
                            </div>
                        </div>

                        <div class="cookie-warning-content__main">
                            <div class="cookie-warning-content__item cookie-warning-content__item--active">
                                <p>
                                    Nutné cookies pomáhají, aby byla webová stránka použitelná tak, že umožní základní funkce jako navigace stránky a přístup k zabezpečeným sekcím webové stránky. Webová stránka nemůže správně fungovat bez těchto cookies.
                                </p>

                                <table>
                                    <thead>
                                        <tr>
                                            <td>Jméno</td>
                                            <td>Poskytovatel</td>
                                            <td>Účel</td>
                                            <td>Vypršení</td>
                                            <td>Typ</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>BIGipServer#</td>
                                            <td><a href="">Skupina Cez</a></td>
                                            <td>Používá se pro šíření provozu webové stránky na několik serverů, aby se optimalizovaly doby odezvy.</td>
                                            <td>Session</td>
                                            <td>HTTP</td>
                                        </tr>

                                        <tr>
                                            <td>BIGipServer#</td>
                                            <td><a href="">Skupina Cez</a></td>
                                            <td>Používá se pro šíření provozu webové stránky na několik serverů, aby se optimalizovaly doby odezvy.</td>
                                            <td>Session</td>
                                            <td>HTTP</td>
                                        </tr>

                                        <tr>
                                            <td>BIGipServer#</td>
                                            <td><a href="">Skupina Cez</a></td>
                                            <td>Používá se pro šíření provozu webové stránky na několik serverů, aby se optimalizovaly doby odezvy.</td>
                                            <td>Session</td>
                                            <td>HTTP</td>
                                        </tr>

                                        <tr>
                                            <td>BIGipServer#</td>
                                            <td><a href="">Skupina Cez</a></td>
                                            <td>Používá se pro šíření provozu webové stránky na několik serverů, aby se optimalizovaly doby odezvy.</td>
                                            <td>Session</td>
                                            <td>HTTP</td>
                                        </tr>

                                        <tr>
                                            <td>BIGipServer#</td>
                                            <td><a href="">Skupina Cez</a></td>
                                            <td>Používá se pro šíření provozu webové stránky na několik serverů, aby se optimalizovaly doby odezvy.</td>
                                            <td>Session</td>
                                            <td>HTTP</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="cookie-warning-content__item">
                                Preferenční content
                            </div>

                            <div class="cookie-warning-content__item">
                                Statistické content
                            </div>

                            <div class="cookie-warning-content__item">
                                Marketingové content
                            </div>

                            <div class="cookie-warning-content__item">
                                Neklasifikované content
                            </div>
                        </div>
                    </div>
                </div>

                <div class="cookie-warning-tabs__item">
                    <div class="cookie-warning-tabs__text">
                        Cookies jsou malé textové soubory, které mohou být používány webovými stránkami, aby učinily uživatelský zážitek více efektivní.<br /><br />

                        Aby se mohly webové stránky správně zobrazovat, potřebujeme sbírat tzv. technické cookies. Pro všechny ostatní typy cookies potřebujeme váš souhlas.<br /><br />

                        Tato stránka používá různé typy cookies. Některé cookies jsou umístěny službami třetích stran, které se objevují na našich stránkách.<br /><br />

                        Kdykoliv můžete změnit nebo zrušit svůj souhlas prostřednictvím Vyjádření o souborech cookie na našich webových stránkách.<br /><br />

                        Zjistěte v našich zásadách ochrany osobních údajů více o tom, kdo jsme, jak nás můžete kontaktovat a jak zpracováváme vaše osobní údaje.<br /><br />

                        Uvedte prosím ID svého souhlasu a datum, kdy nás budete kontaktovat ohledne vašeho souhlasu.
                    </div>
                </div>
            </div>
        </div>

        <div class="cookie-warning__note">
            Prohlášení o cookies bylo naposledy aktualizováno 08.10.21 Cookiebot
        </div>
    </div>
</div>