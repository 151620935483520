import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {CartChangeModalComponent} from "./cart-change-modal.component";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";
import {CustomPipesModule} from "../pipe/custom-pipes.module";
import {ProductCarouselModule} from "../product-carousel/product-carousel.module";
import {PriceModule} from "../price/price.module";
import {ProductBoxModule} from "../product-box/product-box.module";
import {ProductListModule} from "../product-list/product-list.module";

@NgModule({
    imports: [
        CommonModule,
        ImageCacheBustModule,
        RouterModule,
        GeneralDialogModule,
        CustomPipesModule,
        ProductCarouselModule,
        PriceModule,
        ProductBoxModule,
        ProductListModule
    ],
    declarations: [CartChangeModalComponent],
    exports: [CartChangeModalComponent]
})

export class CartChangeModalModule {}
