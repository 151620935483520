import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";

@Component({
    selector: 'cmp-shop-advantages',
    templateUrl: './shop-advantages.component.html',
    styleUrls: ['../../../assets/styles/2-components/advantages.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShopAdvantagesComponent extends Translatable implements OnInit {

    @Input() classModificator: string;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

}
