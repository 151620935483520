/**
 * Module for full article page route.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {FormsModule} from "@angular/forms";
import {BreadcrumbsModule} from "../breadcrumbs/breadcrumbs.module";
import {ArticleMainMenuComponent} from "./article-main-menu.component";
import {RouterModule} from "@angular/router";
import {ArticleService} from "./article.service";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {ArticleNewsComponent} from "./article-news.component";


@NgModule({
    imports: [
        BreadcrumbsModule,
        CommonModule,
        FormsModule,
        ImageCacheBustModule,
        RouterModule,
        PaginationModule.forRoot()
    ],
    declarations: [
        ArticleMainMenuComponent,
        ArticleNewsComponent
    ],
    exports: [
        ArticleMainMenuComponent,
        ArticleNewsComponent
    ],
    providers: [
        ArticleService
    ]
})

export class ArticleModule {
}
