import {Pipe, PipeTransform} from '@angular/core';
import {IAvailability, IProductAvailability} from "../commons/commons";
import {ProductVariantBriefSelector} from "../../../interfaces/general";
import {IProductBoxModel} from "../../product/common";

@Pipe({
    name: 'VariantAvailability'
})
export class VariantAvailabilityPipe implements PipeTransform {

    transform(value: ProductVariantBriefSelector): IAvailability {
        if (!value) {
            return null;
        }

        return {
            Amount: value.qtyInStock,
            OnRequest: (!value.qtyInStock || value.qtyInStock < 0) && value.confirmed === false,
            InStockSoon: (!value.qtyInStock || value.qtyInStock < 0) && value.confirmed === true,
        };
    }

}

@Pipe({
    name: 'ProductBoxModelAvailability'
})
export class ProductBoxModelAvailabilityPipe implements PipeTransform {

    transform(value: IProductBoxModel): IAvailability {
        if (!value) {
            return null;
        }

        return {
            Amount: value.qtyInStock,
            OnRequest: (!value.qtyInStock || value.qtyInStock < 0) && value.confirmed === false,
            InStockSoon: (!value.qtyInStock || value.qtyInStock < 0) && value.confirmed === true,
        };
    }

}

@Pipe({
    name: 'ProductAvailabilityToAvailability'
})
export class ProductAvailabilityToAvailability implements PipeTransform {

    transform(value: IProductAvailability): IAvailability {
        if (!value) {
            return null;
        }

        return {
            Amount: value.InStockCount,
            OnRequest: (!value.InStockCount || value.InStockCount < 0) && value.ConfirmedCount <= 0,
            InStockSoon: (!value.InStockCount || value.InStockCount < 0) && value.ConfirmedCount > 0,
        };
    }

}
