import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'cmp-ask-expert',
    templateUrl: './ask-expert.component.html',
    styleUrls: ['../../../assets/styles/2-components/help-box.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AskExpertComponent extends Translatable implements OnInit {

    @Input() classModificator: string;

    phoneNoteHtml: SafeHtml;
    emailNoteHtml: SafeHtml;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private sanitizer: DomSanitizer
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.phoneNoteHtml = this.sanitizer.bypassSecurityTrustHtml(this.sen['ask-expert--contact1--line1']);
        this.emailNoteHtml = this.sanitizer.bypassSecurityTrustHtml(this.sen['ask-expert--contact2--line1']);
    }

}
