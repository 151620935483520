import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GoogleSignUpButtonComponent} from './google-sign-up-button/google-sign-up-button.component';
import {AlreadySignedUpDialogModule} from "../already-signed-up-dialog/already-signed-up-dialog.module";

@NgModule({
    declarations: [
        GoogleSignUpButtonComponent
    ],
    imports: [
        CommonModule,
        AlreadySignedUpDialogModule
    ],
    exports: [
        GoogleSignUpButtonComponent
    ]
})
export class GoogleSignUpModule {
}
