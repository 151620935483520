<!--Product list component-->
<ng-container *ngIf="!products">
    <br /><br />
    <cmp-alert-box [text]="sen['catalog-no-products']" [mode]="'info'"></cmp-alert-box>
</ng-container>

<ng-container *ngIf="ViewType === ProductViewType.Box">
    <div class="product-list product-list--box" [ngClass]="modificator ? 'product-list--'+modificator : '' " *ngIf="products && products.length">
        <cmp-product-box *ngFor="let product of products" [productboxproduct]="product | ProductSelectorToProductBox"></cmp-product-box>
    </div>
</ng-container>

<ng-container *ngIf="ViewType === ProductViewType.Row">
    <div class="product-list product-list--row" [ngClass]="modificator ? 'product-list--'+modificator : '' " *ngIf="products && products.length">
        <cmp-product-row *ngFor="let product of products" [productboxproduct]="product | ProductSelectorToProductBox"></cmp-product-row>
    </div>
</ng-container>
