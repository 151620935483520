import {IProductBoxModel, ProductSelector} from "../modules/product/common";
import {IProductAmountSettings} from "../modules/product-amount/interfaces/IProductAmountSettings";
import {ProductVariantBriefSelector} from "../interfaces/general";

export class ProductAmountAdapter implements IProductAmountSettings {

    constructor(product: ProductSelector) {
        try {
            this.Factor = product.variants[0].factor || 1;
            this.MaxQuantity = product.variants[0].maxQtyOrder;
            this.MinQuantity = product.variants[0].minQtyOrder || 1;
            this.ProductId = product.variants[0].id;
            this.Unit = product.variants[0].unit?.shortCut;
        }
        catch {
            debugger;
        }
    }

    Factor: number;
    MaxQuantity: number;
    MinQuantity: number;
    ProductId: number;
    Unit: string;

}

export class ProductVariantAmountAdapter implements IProductAmountSettings {

    constructor(product: ProductVariantBriefSelector) {
        this.Factor = product.factor || 1;
        this.MaxQuantity = product.maxQtyOrder;
        this.MinQuantity = product.minQtyOrder || 1;
        this.ProductId = product.id;
        this.Unit = product.unit?.shortCut;
    }

    Factor: number;
    MaxQuantity: number;
    MinQuantity: number;
    ProductId: number;
    Unit: string;

}

export class ProductBoxModelAmountAdapter implements IProductAmountSettings {

    constructor(product: IProductBoxModel) {
        this.Factor = product.factor || 1;
        this.MaxQuantity = product.maxQtyOrder;
        this.MinQuantity = product.minQtyOrder || 1;
        this.ProductId = product.variantId;
        this.Unit = product.unit?.shortCut;
    }

    Factor: number;
    MaxQuantity: number;
    MinQuantity: number;
    ProductId: number;
    Unit: string;

}