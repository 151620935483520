<div class="breadcrumb" *ngIf="bcInfoInternal && bcInfoInternal.length">
    <div class="breadcrumb__inner">
        <ng-container *ngFor="let bc of bcInfoInternal; let lst = last;">
            <ng-container *ngIf="!lst">
                <a [routerLink]="bc.link" class="breadcrumb__item">{{bc.displayName}}</a>

                <div class="breadcrumb__divider">
                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-right" />
                        </svg>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="lst" class="breadcrumb__current">{{bc.displayName}}</div>
        </ng-container>
    </div>
</div>