import {Component, Input, ViewEncapsulation} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {CookiesConsentService} from "./cookies-consent.service";
import {CookiesConsentCookieSelector} from "./common";
import {take} from "rxjs/operators";

@Component({
    selector: 'cmp-cookies-list',
    templateUrl: '../../tpl/cookies-list.html',
    styleUrls: ['../../assets/styles/2-components/cookies-panel.scss', './styles.css'],
    encapsulation: ViewEncapsulation.None
})

export class CookiesListComponent extends Translatable {

    private _groupId:number;
    @Input() set groupId(value:number){
        this._groupId = value;
        this.getCookies(value);
    }
    get groupId():number{
        return this._groupId;
    }

    cookies:Array<CookiesConsentCookieSelector>;

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private ccSvc: CookiesConsentService) {
        super(dataSvc, seSvc);
    }


    private getCookies(groupid: number):void {
        this.ccSvc.dataLoadedEvent.pipe(take(1))
            .subscribe(()=>{
                this.cookies = this.ccSvc.getCookies(groupid);
            });
    }

    getCookieExpiration(cookie: CookiesConsentCookieSelector):string {
        return cookie?.maxAge?.toString();
    }

    getCookieType(cookie: CookiesConsentCookieSelector):string {
        return cookie?.type;
    }
}