/**
 * Serves article data, can be injected when needed (child injector).
 */
import {Injectable, Input, Directive} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ArticleBaseWithChildrenSelector, ArticleBriefSelector, ArticleSelector} from "../../interfaces/general";
import {AsyncSubject, Observable} from "rxjs";
import {ArticleConfig, IArtPageRequest, IArtPageResult} from "../article-list/interfaces";
import {ToQueryStringParam} from "../../helpers/string.helper";


@Directive()
@Injectable()
export class ArticleService {

    @Input() childIndex: number = -1;

    private footerContainerArticleCalled: boolean = false;
    private _asyncSubject: AsyncSubject<ArticleBaseWithChildrenSelector> = new AsyncSubject<ArticleBaseWithChildrenSelector>();

    private _config: ArticleConfig;
    get config(): ArticleConfig {
        return this._config;
    }

    get pageSize(): number {
        return this.config.pageSize;
    }
    set pageSize(value: number) {
        this.config.pageSize = value;
    }

    get pageIndex(): number {
        return this.config.pageIndex;
    }
    set pageIndex(value: number) {
        this.config.pageIndex = value;
    }

    constructor(private http: HttpClient) {
        this.init();
    }

    private init(): void {
        this._config = this.getNewConfig();
    }

    private getNewConfig(): ArticleConfig {
        return new ArticleConfig({
            pageSizeIndex: 0,
            pageIndex: 1,
            pageSizes: [{value: 12}],
        })
    }

    getArticle(id: string): any {
        return this.http.get<ArticleSelector>(`api/article/${id}`);
    }

    getPagedArticles(parentId: string): Observable<IArtPageResult> {
        const request = this.getArticlesRequest(parentId);
        return this.http.get<IArtPageResult>(`api/article/get-filtered?qs=${ToQueryStringParam(request)}`);
    }

    getBreadcrumbs(id: string): any {
        return this.http.get(`api/article/breadcrumb/${id}`);
    }

    getNews(): Observable<Array<ArticleBriefSelector>> {
        return this.http.get<Array<ArticleBriefSelector>>('api/article/news');
    }

    getNewsAndAcademy(): Observable<ArticleBriefSelector[]> {
        return this.http.get<ArticleBriefSelector[]>('api/article/news-and-academy');
    }

    getFooterArticles(): Observable<ArticleBaseWithChildrenSelector> {
        //https://stackoverflow.com/questions/33675155/creating-and-returning-observable-from-angular-2-service
        if (!this.footerContainerArticleCalled) {
            this.footerContainerArticleCalled = true;
            let sub = this.http.get<ArticleBaseWithChildrenSelector>(`api/article/getFooterMenuLinks`)
                .subscribe(
                    (res) => {
                        this._asyncSubject.next(res);
                        this._asyncSubject.complete();
                        sub.unsubscribe();
                    }
                );
        }
        return this._asyncSubject;
    }



    private getArticlesRequest(parentId: string): IArtPageRequest {
        return {
            PageIndex: this.config.pageIndex,
            PageSize: this.config.pageSize,
            ParentId: parentId,
        };
    }
}
