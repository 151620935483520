import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FacebookSignUpButtonComponent} from './facebook-sign-up-button/facebook-sign-up-button.component';
import {AlreadySignedUpDialogModule} from "../already-signed-up-dialog/already-signed-up-dialog.module";


@NgModule({
    declarations: [
        FacebookSignUpButtonComponent
    ],
    imports: [
        CommonModule,
        AlreadySignedUpDialogModule
    ],
    exports: [
        FacebookSignUpButtonComponent
    ]
})
export class FacebookSignUpModule {
}
