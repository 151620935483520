<!--<button class="modal-login__facebook btn btn--medium" style="background-color: indianred" (click)="executeCaptcha()">
	G
	<div class="icon">
		<svg>
			<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#google"/>
		</svg>
	</div>

	{{sen['login-by-google-account']}}
</button>
<label *ngIf="captchaFailed">Captcha failed</label>
-->
<div id="g-btn"></div>