<div class="validation" *ngIf="control?.errors && control.touched && control.dirty">
    <cmp-val-msg *ngIf="control.errors['exists']" [msg]="s('validation-message--exists')"></cmp-val-msg>
    <cmp-val-msg *ngIf="control.errors['required']" [msg]="s('validation-message--required')"></cmp-val-msg>
    <cmp-val-msg *ngIf="control.errors['maxlength']" [msg]="s('validation-message--maxlength') | replace: '#REQUIREDLENGTH#': control.errors['maxlength'].requiredLength | replace: '#ACTUALLENGTH#': control.errors['maxlength'].actualLength"></cmp-val-msg>
    <cmp-val-msg *ngIf="control.errors['emailPattern']" [msg]="s('validation-message--email-pattern')"></cmp-val-msg>
    <cmp-val-msg *ngIf="control.errors['phoneNumberPattern']" [msg]="s('validation-message--phone-number-pattern')"></cmp-val-msg>
    <cmp-val-msg *ngIf="control.errors['icoExists']" [msg]="s('validation-message--ico-exists')"></cmp-val-msg>

    <ng-content></ng-content>
</div>

<!--<cmp-debug-box>-->
<!--    <pre>{{control.errors | json}}</pre>-->
<!--</cmp-debug-box>-->