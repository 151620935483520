/**
 * Created by jan.hroch on 27.06.2017.
 */
import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {Culture, ISearchResult, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";

@Component({
    selector: 'cmp-flex-suggest-list',
    templateUrl: '../../tpl/flex-suggest-list.html',
    styleUrls: ['../../assets/styles/2-components/whisperer.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FlexSuggestListComponent extends Translatable {

    @Input() set expression(value: string) {
        this.searchExpressionWords = value.trim().split(' ').map(x => x.trim()).filter(f => f !== '');
    }

    @Input() suggestResult: ISearchResult;
    @Output() queryHide: EventEmitter<boolean>;
    @Input() phrase: string;
    @Input() loading: boolean = false;

    imagePathPrefix: string;
    culture: Culture;
    currencySymbol: string;

    decimalPattern:string='1.0-0';
    searchExpressionWords: string[];

    constructor(dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);

        this.imagePathPrefix = this.seSvc.settings.newImageServerUrl;
        this.queryHide = new EventEmitter();
        this.culture = this.seSvc.culture;
        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.Whisperer}-${this.seSvc.settings.decimalSettings.Whisperer}`;
    }

    linkClicked(): void {
        this.queryHide.emit(true);
    }

    onComponentClick(evt: any): void {
        evt.stopPropagation();
    }

}
