
import {Component, HostListener, Input, Renderer2, ViewEncapsulation} from "@angular/core";
import {CategoryItemSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {MenuBehavior} from "./common";
import {MenuService} from "../../services/menu.service";
import {document} from "ngx-bootstrap/utils";
import {Router} from "@angular/router";

declare let $: any;

@Component({
    selector: 'cmp-menu',
    templateUrl: '../../tpl/menu.html',
    styleUrls: ['../../assets/styles/3-layout/menu.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MenuComponent extends Translatable {

    @Input() menuBehavior: MenuBehavior = MenuBehavior.default;

    private menuActive: boolean = false;
    activeItems:Array<number> =[];
    private ngUnsubscribe: Subject<any> = new Subject<any>();

    categories: Array<CategoryItemSelector>;
    menuImagePathPrefix: string;

    private _js_menu_element: any;
    private get js_menu_element(): any {
        if (!this._js_menu_element) {
            //this._js_menu_element = this.elementRef.nativeElement.querySelector('.js-menu');
            this._js_menu_element = document.body.querySelector('.js-menu');
        }

        return this._js_menu_element;
    }

    private _menu_element: any;
    private get menu_element(): any {
        if (!this._menu_element) {
            //this._menu_element = this.elementRef.nativeElement.querySelector('.menu');
            this._menu_element = document.body.querySelector('.menu');
        }

        return this._menu_element;
    }

    private _responsive_menu_element: any;
    private get responsive_menu_element(): any {
        if (!this._responsive_menu_element) {
            //this._responsive_menu_element = this.elementRef.nativeElement.querySelector('.responsive-menu');
            this._responsive_menu_element = document.body.querySelector('.responsive-menu');
        }

        return this._responsive_menu_element;
    }


    private _navigation_element: any;
    private get navigation_element(): any {
        if (!this._navigation_element) {
            //this._navigation_element = this.elementRef.nativeElement.querySelector('.navigation');
            this._navigation_element = document.body.querySelector('.navigation');
        }

        return this._navigation_element;
    }

   /* private _html_element: any;
    private get html_element(): any {
        if (!this._html_element) {
            //this._menu_element = this.elementRef.nativeElement.querySelector('.menu');
            this._html_element = document.html.;
        }

        return this._menu_element;
    }*/
    constructor(public dataSvc: DataService, public seSvc: SettingsService, private http: HttpClient,
                /*private elementRef: ElementRef,*/ private renderer: Renderer2, private menuSvc: MenuService,
                private router: Router) {
        super(dataSvc, seSvc);
        this.menuImagePathPrefix = this.seSvc.settings.newImageServerUrl;

        this.menuSvc.toggleMenu
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.toggleMenu();
            });
        this.menuSvc.deactivate
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.deactivateMenu();
            });
    }

    ngOnInit(): void {
        this.getCategories();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    // funkce pro sbalení otevřeného selectu v případě najetí na menu
    onMenuHover(isHovering: boolean): void {
        const selects = document.querySelectorAll('select');
        selects.forEach((select: HTMLSelectElement) => {
            if (isHovering && document.activeElement === select) {
                select.blur();
            }
        });
    }

    private getCategories(): void {
        this.http.get<CategoryItemSelector[]>(`api/category/getRootCategories`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.categories = res;
            });
    }
    rootCategoryClick(catId:number):void{
        this.rootCategoryGet(catId);
        this.setColActiveClass(catId);
    }
    rootCategoryMouseEnter(catId:number):void{
        this.rootCategoryGet(catId);
    }
    rootCategoryGet(catId: number): void {
        const ofInterest = this.categories.filter(f => f.id === catId)[0];
        if (ofInterest.subcategories){
            return;
        }


        this.http.get<CategoryItemSelector[]>(`api/category/getRootCategorySubTree/${catId}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                for (let i = 0; i < this.categories.length; i++) {
                    if (this.categories[i].id === catId) {
                        this.categories[i].subcategories = res.filter(f => f.order != 9999);
                        this.categories[i].sideCategory = res.find(f => f.order == 9999);
                        break;
                    }
                }

            });

    }
    deactivateMenu(){

        if (this.menu_element) {
            this.renderer.removeClass(this.menu_element, "menu--active");
        }
        if (this.responsive_menu_element) {
            this.renderer.removeClass(this.responsive_menu_element, "responsive-menu--active");
        }
        if (this.navigation_element) {
            this.renderer.removeClass(this.navigation_element, "navigation--active");
        }

        this.onMenuHover(false); 

        this.renderer.removeClass(document.body,"menu--active");
        this.renderer.removeClass(document.documentElement,"locked" );
        this.menuActive = false;

    }

    deactivateMenu2(){
        $(document).find(".menu__item--hover").removeClass("menu__item--hover");
        $(document).find(".menu__item--open").removeClass("menu__item--open");
        this.menuActive = false;
    }

    toggleMenu(): void {
        if (!this.menuActive) {
            if (this.menu_element) {
                this.renderer.addClass(this.menu_element, "menu--active");
            }
            if (this.responsive_menu_element) {
                this.renderer.addClass(this.responsive_menu_element, "responsive-menu--active");
            }
            if (this.navigation_element) {
                this.renderer.addClass(this.navigation_element, "navigation--active");
            }
            this.renderer.addClass(document.body,"menu--active");
            this.renderer.addClass(document.documentElement,"locked" );
            this.menuActive = true;
        } else {
            this.deactivateMenu();
        }
    }


    @HostListener('document:click', ['$event'])
    handleClick() {
        if (this.menuBehavior == MenuBehavior.openAndFixedOnIndex && this.dataSvc.pageType == 'index') {
            return;
        }
        if (this.js_menu_element) {
            this.renderer.removeClass(this.js_menu_element, 'menu--active');
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(/*event: KeyboardEvent*/) {

        if (this.menuBehavior == MenuBehavior.openAndFixedOnIndex && this.dataSvc.pageType == 'index') {
            return;
        }
        if (this.js_menu_element) {
            this.renderer.removeClass(this.js_menu_element, 'menu--active');
        }
    }

    toggleActive(event: any) {
        event.stopPropagation();

        if (this.js_menu_element) {
            if (this.js_menu_element.classList.contains('menu--active')) {
                this.renderer.removeClass(this.js_menu_element, 'menu--active');
            } else {
                this.renderer.addClass(this.js_menu_element, 'menu--active');
            }
        }

    }
    isActiveClass(categoryId: number){
        return this.activeItems && this.activeItems.includes(categoryId);
    }
    setColActiveClass(categoryId: number){
            if(this.activeItems && this.activeItems.includes(categoryId)){
                this.activeItems = this.activeItems.filter(x => x !== categoryId);
            }
            else{
                this.activeItems.push(categoryId);
            }
    }
    isActiveCategory(categoryId:number){
        return this.router.url.includes(`c/${categoryId}/`);
    }
}
