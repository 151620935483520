/**
 * Created by petr.humplik on 11.07.2017.
 */
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ProductBoxModule} from "../product-box/product-box.module";
import {HelperComponentsModule} from "../helper-components/helper-components.module";
import {ProductListComponent} from "./product-list.component";
import {ProductRowModule} from "../product-row/product-row.module";
import {AlertBoxModule} from "../alert-box/alert-box.module";

@NgModule({
  imports: [
    CommonModule,
    ProductBoxModule,
    HelperComponentsModule,
    ProductRowModule,
    AlertBoxModule
  ],
    declarations: [
        ProductListComponent
    ],
    exports: [
        ProductListComponent
    ]
})

export class ProductListModule {
}
