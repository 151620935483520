<div class="footer-contact">
    <div class="footer-contact__item">
        <div class="footer-contact__image">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#phone"/>
                </svg>
            </div>
        </div>

        <div class="footer-contact__content">
            <div class="footer-contact__text">
                {{sen['index-opening-hours']}}
            </div>

            <a href="{{'tel: ' + sen['index-phone-number']}}" class="footer-contact__value">
                {{sen['index-phone-number']}}
            </a>
        </div>
    </div>

    <div class="footer-contact__item">
        <div class="footer-contact__image">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#email"/>
                </svg>
            </div>
        </div>

        <div class="footer-contact__content">
            <div class="footer-contact__text">
                {{sen['contact-write-us']}}
            </div>

            <a href="{{'mailto: ' + sen['contact-mail']}}" class="footer-contact__value">
                {{sen['contact-mail']}}
            </a>
        </div>
    </div>
</div>