import {EventEmitter, Injectable, Output} from "@angular/core";

@Injectable()
export class MenuService {
    toggleMenu: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output() deactivate: EventEmitter<void> = new EventEmitter<void>();
    deactivateMenu(){
        this.deactivate.emit();
    }
}
