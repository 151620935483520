import {Pipe, PipeTransform} from '@angular/core';
import {IInterval, Interval} from "../../helpers/interval";

@Pipe({
    name: 'searchExpressionHighlight'
})
export class SearchExpressionHighlightPipe implements PipeTransform {

    transform(value: string, words: string[]): unknown {
        let tmpwordIndexes: IInterval[] = [];
        let wordIndexes: IInterval[] = [];
        let cumulationIndex: number = 0;
        const highlightHtmlCharacterCount: number = '<span></span>'.length;
        const valueWords = value.toLowerCase().split(' ');

        for (let i = 0; i < words?.length; i++) {
            const word = words[i].toLowerCase();

            const idx = value.toLowerCase().indexOf(word, 0);
            if (idx !== -1 && valueWords.some(s => s.startsWith(word))) {
                tmpwordIndexes.push({start: idx, end: idx + word.length});
            }
        }

        tmpwordIndexes = tmpwordIndexes.sort((n1, n2) => n1.start - n2.start);

        for (let i = 0; i < tmpwordIndexes.length; i++) {
            const current = tmpwordIndexes[i];
            let currentInterval: IInterval = {
                start: current.start,
                end: current.end
            }

            for (let j = i + 1; j < tmpwordIndexes.length; j++) {
                const currentJ = tmpwordIndexes[j];

                let isIntersect = !!Interval.findIntervalIntersect(currentInterval, currentJ);
                if (isIntersect) {
                    currentInterval = Interval.findIntervalUnion(currentInterval,currentJ);

                    tmpwordIndexes.splice(j, 1);
                    j--;
                }
            }

            wordIndexes.push(currentInterval);
        }

        for (let i = 0; i < wordIndexes?.length; i++) {
            const res = value.substr(0, (wordIndexes[i].start) + cumulationIndex) +
                `<span>${value.substr(wordIndexes[i].start + cumulationIndex, wordIndexes[i].end - wordIndexes[i].start)}</span>` +
                value.substr((wordIndexes[i].start + cumulationIndex) + (wordIndexes[i].end - wordIndexes[i].start));
            value = res;
            cumulationIndex += highlightHtmlCharacterCount;
        }

        return value;
    }

}