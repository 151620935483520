import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {IAvailability} from "../commons/commons";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";

export enum AvailabilityAppereanceType {
    Default = 0,
    ProductDetail = 1
}

@Component({
    selector: 'cmp-availability',
    templateUrl: './availability.component.html',
    styleUrls: ["../../../assets/styles/2-components/availability.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AvailabilityComponent extends Translatable implements OnInit {

    AvailabilityAppereanceType = AvailabilityAppereanceType;

    @Input() type: AvailabilityAppereanceType = AvailabilityAppereanceType.Default;
    @Input() availability: IAvailability = null;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

}
