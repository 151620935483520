<div class="search">
    <div class="search__close" (click)="toggleSearch()"></div>

    <span class="search__icon icon icon-search"></span>

    <input noAutoComplete id="js-search" type="text" #phrase class="js-search search__input" title="{{sen['app-search-placeholder']}}" (keyup)="searchKeyUp($event)" (keypress)="searchKeyPress($event, phrase.value)" name="q" [placeholder]="sen['app-search-placeholder']">

    <button class="search__button" (click)="search(phrase.value)">
      {{sen['app-search']}}
    </button>
</div>