/**
 * serves as a reporter of unhandled JS errors
 * errors are reported to tblLog with sSubject = 'GEH JS Error'
 */
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {DigestService} from "./digest.service";
import {HttpClient} from "@angular/common/http";
import {ErrorSelector} from "../interfaces/general";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {
    }

    handleError(error: any): void {
        const digestSvc: DigestService = <DigestService>this.injector.get(DigestService);
        const http: HttpClient = <HttpClient>this.injector.get(HttpClient);
        let errorSelector: ErrorSelector;
        let tmpMessage = '', tmpStack = '';

        try {
            if (error.hasOwnProperty('message') || error.hasOwnProperty('stack')) {
                if (error.hasOwnProperty('message')) {
                    tmpMessage = error['message']
                }
                if (error.hasOwnProperty('stack')) {
                    tmpStack = error['stack']
                }
                errorSelector = {
                    message: `message: ${tmpMessage} | stack: ${tmpStack}`
                }

            } else {
                errorSelector = {
                    message: JSON.stringify(error)
                }
            }

        } catch (e) {
            errorSelector = {
                message: error.toString()
            };
        }
        http.post<ErrorSelector>('api/error/report', errorSelector)
            .subscribe(() => {});

        if (error.status && error.status == 403) {
            digestSvc.logOut();
        }
        throw error;
    }

}