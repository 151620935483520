import {Component, OnInit} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";


@Component({
    selector: 'cmp-facebook-sign-up-button',
    templateUrl: './facebook-sign-up-button.component.html'
})
export class FacebookSignUpButtonComponent extends Translatable implements OnInit {

    captchaFailed: boolean = false;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

    facebookLogin() {
        const url = "https://www.facebook.com/v19.0/dialog/oauth"
            + "?client_id=" + this.seSvc.facebookSettings.facebookLoginAppId
            + "&scope=email"
            + "&redirect_uri=" + location.protocol + "//" + location.host + "/?facebookLogin=1";

        location.assign(url);
    }
}
