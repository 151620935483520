/**
 * @description
 * Provides functionality for logging user in and out
 * The template of this component is the place to place Login Control GUI
 * In case of a need to implement custome validators, look here:
 * https://angular.io/docs/ts/latest/cookbook/form-validation.html#!#custom-validation
 */
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {DigestService} from "../../services/digest.service";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {NavigationStart, Router} from "@angular/router";
import {isRouteSecured} from "../../helpers/string.helper";
import {Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {CredentialStorage} from "../../services/credential-storage.service";
import {AuthInfo, LoginEmitterMessage, Translatable} from "../../interfaces/general";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";
import {LoginDialogComponent} from "./login-dialog.component";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";

@Component({
    selector: 'cmp-login',
    templateUrl: '../../tpl/login.html',
    styleUrls: ['../../assets/styles/3-layout/header/user.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LoginComponent extends Translatable {
    @Input() classModificator: string;

    //displayUser: string;
    loggedIn: boolean = false;
    errorMessage: string;
    ngUnsubscribe: Subject<any> = new Subject<any>();
    isDropdownMenuOpen: boolean = false;

    public static isAlreadySubscribedToLoginDialog: boolean = false;

    get displayUser(): string {
        let ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            this.loggedIn = ai.loggedIn;
            if (this.loggedIn) return ai.displayName;
            else return '';
        }
        else {
            return '';
        }
    }

    constructor(dataSvc: DataService, seSvc: SettingsService, public digestSvc: DigestService,
                private router: Router, private generalDialogSvc: GeneralDialogService) {
        super(dataSvc, seSvc);

        if(LoginComponent.isAlreadySubscribedToLoginDialog === false) {
            this.digestSvc.onLoginQuery
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.showDialog();
                });

            LoginComponent.isAlreadySubscribedToLoginDialog = true;
        }

        let ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            this.loggedIn = ai.loggedIn;
        }


        this.digestSvc.loginStatus
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: LoginEmitterMessage) => {
                if (res.type === 'error') this.errorMessage = this.sen['login-error'];
                else this.errorMessage = null;
            });
    }

    /**
     * @description
     * Logs user off by deleting Authorization header info from session / local
     * You can optionally show a message
     */
    logOut(): void {
        this.digestSvc.logOut(true);
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter(f => f instanceof NavigationStart),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((res: NavigationStart) => {
                if (!isRouteSecured(res.url)) return;

                let ai: AuthInfo = CredentialStorage.authInfo;
                if (ai && !/^\/odhlaseni$/.test(res.url)) {
                    if (!CredentialStorage.isAiTimeValid()) {
                        this.digestSvc.logOut();
                    }
                }
            })
    }

    showDialog(): void {
        const config: GeneralDialogConfig = {
            data: null,
            cssClassModifier: 'login',
            isClosable: true,
            title: this.sen['app-user-login-title']
        };
        this.generalDialogSvc.open(LoginDialogComponent, config);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onMouseEnterMain(): void {
        this.isDropdownMenuOpen = true;
    }

    onMouseEnterDropdown(): void {
        this.isDropdownMenuOpen = true;
    }

    onMouseLeaveDropdown(): void {
        this.isDropdownMenuOpen = false;
    }

}
