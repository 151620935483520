import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AlertBoxMode} from './interfaces/common';

@Component({
    selector: 'cmp-alert-box',
    templateUrl: '../../tpl/alert-box.html',
    styleUrls: ["../../assets/styles/2-components/alert.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AlertBoxComponent implements OnInit {

    @Input() text: string;
    @Input() mode: AlertBoxMode = 'info';

    constructor() {
    }

    ngOnInit(): void {
    }
}
