<div class="account-menu" *ngIf="!isDropdownMenu">
    <h2 class="account-menu__title heading-h2">
        {{sen['account-menu-title']}}
    </h2>

    <div class="account-menu__in">
        <div class="account-menu__list js-nav">
            <ng-container *ngFor="let item of accMenuSvc.menuItems">
            <a *ngIf="item.isShownInSide" [routerLink]="[item.routerLink]" class="account-menu__item" [class.account-menu__item--active]="item.isActive" (click)="accMenuSvc.setActive(item.routerLink)">
                <div class="account-menu__image">
                    <img src="{{seSvc.settings.assetPathPrefix}}/{{item.isActive ? item.iconActive : item.iconNotActive}}" width="28" height="28" />
                </div>

                {{sen[item.sideItem]}}
            </a>
            </ng-container>

            <a href="javascript:void(0);" (click)="SignOut()" class="account-menu__item">
                <div class="account-menu__image">
                    <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/account/logout.svg'" alt="{{sen['app-sign-out']}}"/>
                </div>

                {{sen['app-sign-out']}}
            </a>
        </div>
    </div>
</div>

<ng-container *ngIf="isDropdownMenu">
    <div class="user-menu" (mouseenter)="showDropdown()" (mouseleave)="hideDropdown()">
        <div class="user-menu__in" [class.user-menu__scrolled]="isPageScrolled()" *ngIf="isDropdownOpen">
            <ng-container *ngFor="let item of accMenuSvc.menuItems">
                <a *ngIf="item.isShownInTop" [routerLink]="item.routerLink" [class.active]="item.isActive" class="user-menu__item" (click)="accMenuSvc.setActive(item.routerLink)">
                    <img src="{{seSvc.settings.assetPathPrefix}}/{{item.isActive ? item.iconActive : item.iconNotActive}}" width="28" height="28" />

                    {{sen[item.sideItem]}}
                </a>
            </ng-container>

            <a href="javascript:void(0);" (click)="SignOut()" class="user-menu__item">
                <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/account/logout.svg'" alt="{{sen['app-sign-out']}}"/>

                {{sen['app-sign-out']}}
            </a>
        </div>
    </div>
</ng-container>