import {ApplicationRef, ComponentFactoryResolver, Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralDialogComponent} from "./general-dialog.component";
import {GeneralDialogInsertionDirective} from "./general-dialog-insertion.directive";
import {GeneralDialogService} from "./general-dialog.service";
import {GeneralDialogMessageBoxComponent} from './general-dialog-message-box/general-dialog-message-box.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        GeneralDialogMessageBoxComponent
    ],
    declarations: [
        GeneralDialogComponent,
        GeneralDialogInsertionDirective,
        GeneralDialogMessageBoxComponent
    ],
    providers: [
        {
            provide: GeneralDialogService,
            useClass: GeneralDialogService,
            deps: [ComponentFactoryResolver, ApplicationRef, Injector]
        }
    ]
})

export class GeneralDialogModule {
}
