<div *ngIf="availability" class="availability" [ngClass]="{'availability--detail': type === AvailabilityAppereanceType.ProductDetail, 'availability--green': availability.Amount > 0, 'availability--red': availability.Amount <= 0 && availability.OnRequest, 'availability--orange': availability.InStockSoon}">
    <div class="icon" *ngIf="type === AvailabilityAppereanceType.ProductDetail">
        <svg>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#boxes"/>
        </svg>
    </div>

    <ng-container *ngIf="availability.InStockSoon; else elseOnRequest">
        {{sen['availability--in-stock-soon']}}
    </ng-container>

    <ng-template #elseOnRequest>
        <ng-container *ngIf="availability.OnRequest; else elseAmount">
            {{sen['availability--on-request']}}
        </ng-container>

        <ng-template #elseAmount>
            <ng-container *ngIf="availability.Amount <= 0">
                {{sen['availability--no-product']}}
            </ng-container>

            <ng-container *ngIf="0 < availability.Amount && availability.Amount <= 10">
                {{sen['availability--in-stock-to']}} {{'10'}} {{sen['app-pcs']}}
            </ng-container>

            <ng-container *ngIf="10 < availability.Amount && availability.Amount <= 100">
                {{sen['availability--in-stock-above']}} {{'10'}} {{sen['app-pcs']}}
            </ng-container>

            <ng-container *ngIf="100 < availability.Amount">
                {{sen['availability--in-stock-above']}} {{'100'}} {{sen['app-pcs']}}
            </ng-container>

        </ng-template>
    </ng-template>
</div>