import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HeaderInvoiceComponent} from "./header-invoice/header-invoice.component";
import {RouterModule} from "@angular/router";
import {PriceModule} from "../price/price.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PriceModule
    ],
    declarations: [
        HeaderInvoiceComponent
    ],
    exports: [
        HeaderInvoiceComponent
    ]
})
export class HeaderInvoiceModule {
}