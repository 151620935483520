import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";

@Component({
    selector: 'cmp-validation',
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ValidationComponent extends Translatable implements OnInit {

    @Input() control: AbstractControl = null;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
    }

}
