import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AvailabilityComponent} from './availability/availability.component';
import {
    ProductAvailabilityToAvailability,
    ProductBoxModelAvailabilityPipe,
    VariantAvailabilityPipe
} from './pipes/variant-availability.pipe';

@NgModule({
    declarations: [
        AvailabilityComponent,
        VariantAvailabilityPipe,
        ProductBoxModelAvailabilityPipe,
        ProductAvailabilityToAvailability
    ],
    exports: [
        AvailabilityComponent,
        VariantAvailabilityPipe,
        ProductBoxModelAvailabilityPipe,
        ProductAvailabilityToAvailability
    ],
    imports: [
        CommonModule
    ]
})
export class AvailabilityModule {
}
