import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductRowComponent} from './product-row/product-row.component';
import {RouterModule} from "@angular/router";
import {CustomPipesModule} from "../pipe/custom-pipes.module";
import {AvailabilityModule} from "../availability/availability.module";
import {PriceModule} from "../price/price.module";
import {ProductAmountModule} from "../product-amount/product-amount.module";
import {WishListModule} from "../wish-list/wish-list.module";

@NgModule({
    declarations: [
        ProductRowComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    CustomPipesModule,
    AvailabilityModule,
    PriceModule,
    ProductAmountModule,
    WishListModule
  ],
    exports: [
        ProductRowComponent
    ]
})
export class ProductRowModule {
}
