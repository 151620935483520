<div class="product-box product-box-vk-{{item.variantKey}}" [ngClass]="{'product-box--not-available': item.accessLevel !== 2}" *ngIf="item">
    <div *ngIf="showCloseButton" class="product-box__remove" (click)="onCloseButtonClicked()" title="{{sen['wishlist-remove']}}">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cross"/>
            </svg>
        </div>
    </div>

    <ng-container *ngIf="IsVariant == false">
        <cmp-wish-list-button [type]="'product-box'" [variantId]="item.variantId"></cmp-wish-list-button>
    </ng-container>

    <div [id]="item?.variantKey" *ngIf="showFlags" class="flags">
        <div *ngIf="IsVariant" class="flags__item flags__item--blue">
            {{item.variantsCount}} variant

            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#ribbon"/>
                </svg>
            </div>
        </div>

        <div *ngIf="item.discount < 0" class="flags__item flags__item--red">
            {{item.discount | number:'1.0-0'}}%

            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#ribbon"/>
                </svg>
            </div>
        </div>

        <div *ngIf="item?.isNew" class="flags__item flags__item--blue">
            {{sen['bNew']}}

            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#ribbon"/>
                </svg>
            </div>
        </div>

        <div *ngIf="item?.isAction" class="flags__item flags__item--blue">
            {{sen['bAction']}}

            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#ribbon"/>
                </svg>
            </div>
        </div>

        <div *ngIf="item?.isSellOut" class="flags__item flags__item--red">
            {{sen['bSellOut']}}

            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#ribbon"/>
                </svg>
            </div>
        </div>

        <div *ngIf="item?.isRecommended" class="flags__item flags__item--blue-light">
            {{sen['bRecommended']}}

            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#ribbon"/>
                </svg>
            </div>
        </div>
    </div>

    <div class="product-box__image" [id]="item?.variantKey">
        <a [routerLink]="[item?.urlToDisplay]" (click)="onClickDetail()">
            <picture>
                <source [srcset]="(imageServerUrl + '/catalog/280/280/' + item?.imagePath) | webp" type="image/webp"/>
                <img [src]="imageServerUrl + '/catalog/280/280/' + item?.imagePath" [alt]="item?.imageTitle?item?.imageTitle:item?.name" [title]="item?.imageTitle?item?.imageTitle:''"/>
            </picture>
        </a>
    </div>

    <div class="product-box__content">
        <a [routerLink]="[item?.urlToDisplay]" (click)="onClickDetail()" class="product-box__title">
            {{item?.name}}
        </a>

        <div class="product-box__availability-prices">
            <ng-container *ngIf="IsVariant == false; else elseVariant">
<!--                <cmp-availability [availability]="item | ProductBoxModelAvailability"></cmp-availability>-->
                <cmp-availability [availability]="availability | ProductAvailabilityToAvailability"></cmp-availability>

                <div class="prices prices--right">
                    <div class="prices__item prices__item--main">
                        <cmp-price [price]="item.priceWithVAT"></cmp-price>
                    </div>

                    <div class="prices__item prices__item--base">
                        <cmp-price [price]="item.priceWithoutVAT"></cmp-price>
                        {{sen['without-vat']}}
                    </div>
                </div>
            </ng-container>

            <ng-template #elseVariant>
                <div></div>
                <div class="prices prices--right">
                    <div class="prices__item prices__item--main">
                        <ng-container *ngIf="item.priceWithVatRange[0] === item.priceWithVatRange[1]; else elseRange">
                            <cmp-price [price]="item.priceWithVatRange[0]"></cmp-price>
                        </ng-container>
                        <ng-template #elseRange>
                            <cmp-price [price]="item.priceWithVatRange[0]"></cmp-price>
                            -
                            <cmp-price [price]="item.priceWithVatRange[1]"></cmp-price>
                        </ng-template>
                    </div>

                    <div class="prices__item prices__item--base">
                        <ng-container *ngIf="item.priceWithVatRange[0] === item.priceWithVatRange[1]; else elseRangeWithoutVat">
                            <cmp-price [price]="item.priceWithoutVatRange[0]"></cmp-price>
                        </ng-container>
                        <ng-template #elseRangeWithoutVat>
                            <cmp-price [price]="item.priceWithoutVatRange[0]"></cmp-price>
                            -
                            <cmp-price [price]="item.priceWithoutVatRange[1]"></cmp-price>
                            {{sen['without-vat']}}
                        </ng-template>
                    </div>
                </div>
            </ng-template>
        </div>

        <ng-container *ngIf="IsVariant === false; else elseNoVariant">
            <div class="product-box__buy" *ngIf="displayShoppingSection">
                <cmp-product-amount #amount [amount]="item?.count" [data]="item | ProductBoxModelAmount" (confirmed)="changeCount($event.Amount)" (amountChange)="changeCount($event)"></cmp-product-amount>

                <button [disabled]="!amount.IsValid || dataSvc.dataLoading || item?.priceWithVAT == 0" class="product-box__btn btn btn--medium btn--green" (click)="buy(item.variantId, amount.Amount)" data-action="buy" [ngClass]="{'btn--gray': item.priceWithVAT == 0}">
                    {{sen['cart-buy']}}
                </button>
            </div>
        </ng-container>

        <ng-template #elseNoVariant>
            <div class="product-box__buy product-box__buy--center">
                <a [routerLink]="[item?.urlToDisplay]" (click)="onClickDetail()" class="btn btn--medium btn--gray">
                    {{sen['detail-button--label']}}
                </a>
            </div>
        </ng-template>
    </div>
</div>
