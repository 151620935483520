<div class="alert alert--success" *ngIf="mode === 'success'">
    <div>
        {{text}}

        <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </div>
</div>

<div class="alert alert--warning" *ngIf="mode === 'warning'">
    <div>
        {{text}}

        <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </div>
</div>

<div class="alert alert--info" *ngIf="mode === 'info'">
    <div>
        {{text}}

        <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </div>
</div>

<div class="alert alert--error" *ngIf="mode === 'error'">
    <div>
        {{text}}

        <ng-container *ngTemplateOutlet="tpl"></ng-container>
    </div>
</div>

<ng-template #tpl>
    <ng-content></ng-content>
</ng-template>
