import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MenuComponent} from "./menu.component";
import {RouterModule} from "@angular/router";
import {CustomPipesModule} from "../pipe/custom-pipes.module";
import {ArticleModule} from "../articles/article.module";
import {AdvaAcademyMenuModule} from "../adva-academy-menu/adva-academy-menu.module";
import {HeaderOptionModule} from "../header-option/header-option.module";
import {HoverClassModule} from "../../directives/hower-class.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CustomPipesModule,
        ArticleModule,
        AdvaAcademyMenuModule,
        HeaderOptionModule,
        HoverClassModule
    ],
    declarations: [MenuComponent],
    exports: [MenuComponent]
})

export class MenuModule {
}
