import {Component, Input, ViewEncapsulation} from "@angular/core";
import {CategoryItemSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";

@Component({
    selector: 'cmp-adva-adacemy-menu',
    templateUrl: '../../tpl/adva-academy-menu.html',
    styleUrls: ['../../assets/styles/2-components/help-box-menu.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AdvaAcademyMenuComponent extends Translatable {
    @Input() categoryItem: CategoryItemSelector;

    constructor(dataSvc:DataService, seSvc:SettingsService) {
        super(dataSvc, seSvc);
    }

}