import {AfterViewInit, Component, OnInit} from '@angular/core';
import {GoogleApiService} from "../../google-api/services/google-api.service";
import {Translatable} from "../../../interfaces/general";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {DigestService, GoogleLogin} from "../../../services/digest.service";
import {FlexCaptchaService} from "../../../services/flex-captcha.service";

@Component({
    selector: 'cmp-google-sign-up-button',
    templateUrl: './google-sign-up-button.component.html'
})
export class GoogleSignUpButtonComponent extends Translatable implements OnInit, AfterViewInit {

    captchaFailed: boolean = false;
    clientId: string;

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private gapiSvc: GoogleApiService,
        private digestSvc: DigestService,
        private fcSvc: FlexCaptchaService
    ) {
        super(dataSvc, seSvc);

        this.gapiSvc.googleApiResponse.subscribe({
            next: jwt => {
                this.fcSvc.executeCaptcha('login', 'login-dialog.component.onCaptchaExecuted')
                    .subscribe(res => {
                        this.captchaFailed = !res.success;
                        if (res.success) {
                            this.digestSvc.login(new GoogleLogin(jwt, res.secret));
                        }
                    });
            }
        });
    }

    ngOnInit(): void {
        this.clientId = this.gapiSvc.ClientId;
    }

    ngAfterViewInit() {
        this.gapiSvc.scriptLoaded.subscribe({
            next: () => {
                this.gapiSvc.renderBtn('#g-btn');
            }
        });
    }
}
