import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductAmountComponent} from './product-amount/product-amount.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";
import {ProductAmountPipe, VariantAmountPipe} from "./product-amount.pipe";
import { ProductAmountCartPipe } from './product-amount-cart.pipe';
import {ProductBoxModelAmountPipe, ProductVariantAmountPipe} from "./product-variant-amount.pipe";

@NgModule({
    declarations: [
        ProductAmountComponent,
        ProductAmountPipe,
        VariantAmountPipe,
        ProductAmountCartPipe,
        ProductVariantAmountPipe,
        ProductBoxModelAmountPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule
    ],
    exports: [
        ProductAmountComponent,
        ProductAmountPipe,
        VariantAmountPipe,
        ProductAmountCartPipe,
        ProductVariantAmountPipe,
        ProductBoxModelAmountPipe
    ]
})
export class ProductAmountModule {
}
