<ng-container *ngIf="info?.Invoices?.length; else elseNoInvoiceToShow">
    <div class="header-invoices">
        <div class="header-invoices__icon">
            <div class="header-invoices__count">
                {{info?.Invoices?.length}}
            </div>

            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#warning"/>
                </svg>
            </div>
        </div>

        <div class="header-invoices__text">
            <strong>{{sen['invoices-after-maturity']}}:</strong> {{sen['in-sum']}} <span>
                <ng-container *ngFor="let item of info?.TotalPrices; let lst = last">
                    <cmp-price [price]="item.TotalPrice" [currencySymbol]="item.Currency?.DisplayName"></cmp-price>
                    <span *ngIf="!lst && info?.TotalPrices.length != 1"> + </span>
                </ng-container>
            </span>
        </div>

        <div class="header-invoices-box">
            <div class="header-invoices-table">
                <div class="header-invoices-table__head">
                    <div class="header-invoices-table__row">
                        <div class="header-invoices-table__col">
                            {{sen['account-invoice-number']}}
                        </div>

                        <div class="header-invoices-table__col">
                            {{sen['account-maturity-date']}}
                        </div>

                        <div class="header-invoices-table__col">
                            {{sen['days-after-maturity']}}
                        </div>

                        <div class="header-invoices-table__col">
                            {{sen['castka']}}
                        </div>

                        <div class="header-invoices-table__col">
                            {{sen['owe-price']}}
                        </div>
                    </div>
                </div>

                <div class="header-invoices-table__body">
                    <div class="header-invoices-table__row" *ngFor="let item of info?.Invoices">
                        <div class="header-invoices-table__col">
                            <a [routerLink]="['/muj-ucet/faktury', item.InvoiceNumber]">
                                {{item.InvoiceNumber}}
                            </a>
                        </div>

                        <div class="header-invoices-table__col">
                            {{item.MaturityDate | date: 'mediumDate'}}
                        </div>

                        <div class="header-invoices-table__col">
                            {{item.DayCountAfterMaturity}}

                            <ng-container *ngIf="item.DayCountAfterMaturity === 1">{{sen['day-1']}}</ng-container>
                            <ng-container *ngIf="2 <= item.DayCountAfterMaturity &&  item.DayCountAfterMaturity <= 4">{{sen['day-2-4']}}</ng-container>
                            <ng-container *ngIf="item.DayCountAfterMaturity === 0 || 5 <= item.DayCountAfterMaturity">{{sen['day-5']}}</ng-container>
                        </div>

                        <div class="header-invoices-table__col">
                            <cmp-price [price]="item.PriceWithVat" [currencySymbol]="item.Currency?.DisplayName"></cmp-price>
                        </div>

                        <div class="header-invoices-table__col">
                            <cmp-price [price]="item.RemainsToBePaid" [currencySymbol]="item.Currency?.DisplayName"></cmp-price>
                        </div>
                    </div>
                </div>
            </div>

            <div class="header-invoices-price">
                <div class="header-invoices-price__label">
                    {{sen['total-owe-price']}}:
                </div>

                <div class="header-invoices-price__value">
                    <ng-container *ngFor="let item of info?.TotalPrices; let lst = last">
                        <cmp-price [price]="item.TotalPrice" [currencySymbol]="item.Currency?.DisplayName"></cmp-price>
                        <span *ngIf="!lst && info?.TotalPrices.length != 1"> + </span>
                    </ng-container>
                </div>
            </div>

            <div class="header-invoices-box__actions">
                <a [routerLink]="['/muj-ucet/faktury']" class="btn btn--small btn--red">
                    {{sen['all-invoices']}}
                </a>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #elseNoInvoiceToShow>
    <div class="header-invoices">
        <div class="header-invoices__icon">
            <div class="header-invoices__count">
                0
            </div>

            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#warning"/>
                </svg>
            </div>
        </div>

        <div class="header-invoices__text">
            <strong>{{sen['no-invoice-after-maturity']}}</strong>
        </div>

    </div>
</ng-template>