import {Component, ViewEncapsulation,} from '@angular/core';
import {ProductBoxComponent} from "../../product-box/product-box.component";
import {DataService} from "../../../services/data.service";
import {SettingsService} from "../../../services/settings.service";
import {CartService} from "../../cart/cart.service";
import {CompareProductsService} from "../../../services/compare-products.service";
import {PageStateService} from "../../../services/page-state.service";
import {AvailabilityService} from "../../availability/services/availability.service";

@Component({
    selector: 'cmp-product-row',
    templateUrl: './product-row.component.html',
    styleUrls: ['../../../assets/styles/2-components/product-row.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductRowComponent extends ProductBoxComponent {

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        cartSvc: CartService,
        compareSvc: CompareProductsService,
        pageStateSvc: PageStateService,
        availSvc: AvailabilityService
    ) {
        super(dataSvc, seSvc, cartSvc, compareSvc, pageStateSvc, availSvc);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
