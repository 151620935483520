import {EventEmitter, Injectable} from "@angular/core";
import {isRouteSecured} from '../helpers/string.helper';
import {AuthInfo, CartTokenSelector, LoginEmitterMessage, LoginResult} from "../interfaces/general";
import {SettingsService} from "./settings.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {CredentialStorage} from "./credential-storage.service";
import {CartService} from "../modules/cart/cart.service";
import {removeFromSession} from "../helpers/cookie.helper";
import {CartTokenService} from "../modules/cart/cart-token.service";
import {take} from "rxjs/operators";
import {FlexCaptchaService} from "./flex-captcha.service";

declare let moment: any;
declare let StringView: any;

// todo: UNIT TEST FOR THIS SERVICE

@Injectable()
export class DigestService {

    badCredentialsMessage: string = 'bad credentials, login failed';
    errorType: string = 'error';

    userName: string;
    password: string;
    requestedUri: string;
    requestedMethod: string;

    loginStatus: EventEmitter<LoginEmitterMessage>;
    onLoginQuery: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient, private seSvc: SettingsService, private route: ActivatedRoute, private fcSvc: FlexCaptchaService,
                private router: Router, private cartSvc: CartService, private cartTokenSvc: CartTokenService) {

        this.route.queryParams
            //.pipe(take(1))
            .subscribe(params => {
                if (params?.facebookLogin?.toString() === '1' && params?.code) {
                    this.facebookLogin(params.code);
                }
            });

        let aiValid: boolean = false;

        const ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            const mt = moment();
            const mtValidTo = moment(ai.validTo);
            if (mtValidTo > mt) {
                aiValid = true;
            }
        }

        if (!aiValid) {
            /**
             * This means fresh start, nothing in session/local, we settle new values
             */
            DigestService.initNotSigned();
        } else {
            /**
             * this means there was full page reload, so we rather take stored values
             * */
            this.userName = ai.userName;
        }

        this.loginStatus = new EventEmitter();
    }

    private static initNotSigned(): void {
        CredentialStorage.removeAuthInfo();
    }

    public test(): void {
        console.log();
    }

    login(strategy: ILoginConfig): void {

        this.requestedUri = 'api/login/login';
        this.requestedMethod = 'GET';

        let headers: HttpHeaders = strategy.GetHeaders();
        if (strategy.Secret() !== null)
            headers = headers.append('Captcha-Secret', strategy.Secret());

        this.http.get<LoginResult>(this.requestedUri, {headers: headers})
            .subscribe(
                (r) => {

                    CredentialStorage.authInfo = {
                        userName: r.userName,
                        displayName: r.companyDisplayName,
                        loggedIn: true,
                        validTo: moment().add(r.tokenExpirationMinutes, 'minutes').format(),
                        newsletterSubscribed: r.newsletterSubscribed,
                        isB2B: r.isB2B
                    };

                    this.userName = r.userName;

                    this.cartTokenSvc.cartTokens = r.cartTokens;

                    if (!this.seSvc.bindCurrencyAndCultureToDomain) {
                        this.seSvc.setCulture(r.cultureId, r.currencyId);
                    }

                    // BLOCK ADJUST CART

                    /**
                     here you can freely implement any cart-adjusting logic
                     because on Flex there is an agreement that the carts should be allways merged
                     I call merge method
                     */

                    if (r.cartTokens.length > 1) {
                        const url = `api/cart/merge/${r.cartTokens[1].cartToken}/${r.cartTokens[0].cartToken}`;
                        this.http.get<CartTokenSelector[]>(url)
                            .pipe(take(1))
                            .subscribe((mr) => {
                                this.cartTokenSvc.cartTokens = mr;
                                this.redirectAfterLogin(r.reqInfo);
                            });
                    } else {
                        this.redirectAfterLogin(r.reqInfo);
                    }

                    // BLOCK ADJUST CART
                },
                (e: HttpErrorResponse) => this.loginStatus.emit({
                    type: this.errorType,
                    message: this.badCredentialsMessage,
                    data: e
                })
            );
    }

    private redirectAfterLogin(_reqInfo: boolean): void {
        /*        if(_reqInfo){
                    location.href = '/muj-ucet/osobni-udaje';
                } else{*/
        location.reload();
        // }
    }

    logOut(routeToIndex?: boolean) {
        this.http.get(`api/signout`)
            .pipe(take(1))
            .subscribe(() => {
                CredentialStorage.removeAuthInfo();
                this.userName = undefined;
                this.password = undefined;
                removeFromSession('cadj');

                this.cartSvc.cleanCartOnLogout();
                this.cartTokenSvc.clear();

                let fwd = this.router.url;
                let url;
                if (routeToIndex || isRouteSecured(fwd)) {
                    if (routeToIndex) {
                        url = '/';
                    } else {
                        url = fwd ? `/upozorneni?fwd=${fwd}` : '/upozorneni';
                    }
                    this.router.navigateByUrl(url).then(() => {
                        location.reload();
                    });
                } else {
                    location.reload();
                }
            })
    }

    queryLogin(): void {
        this.onLoginQuery.emit();
    }

    /**private getLoginHeaders(): HttpHeaders {
        let result = `Basic username="${this.userName}", password="${this.password}"`;

        return new HttpHeaders({'Authorization': result});
    }**/

    //region Facebook login
    private facebookLogin(code: string): void {

        this.fcSvc.executeCaptcha('facebooklogin', 'digestSvc.facebookLogin')
            .pipe(take(1))
            .subscribe(res => {
                if (res.success) {
                    this.facebookLoginDo(code, res.secret);
                }
            });
    }

    private facebookLoginDo(code: string, secret: string): void {

        this.requestedUri = `api/login/facebook-login`;
        this.requestedMethod = 'GET';

        let headers: HttpHeaders = this.getFacebookLoginHeaders(code);
        headers = headers.append('Captcha-Secret', secret);

        this.http.get<LoginResult>(this.requestedUri, {headers: headers})
            .pipe(take(1))
            .subscribe(
                (r) => {

                    CredentialStorage.authInfo = {
                        userName: r.userName,
                        displayName: r.companyDisplayName,
                        loggedIn: true,
                        validTo: moment().add(r.tokenExpirationMinutes, 'minutes').format(),
                        newsletterSubscribed: r.newsletterSubscribed,
                        isB2B: r.isB2B
                    };

                    this.cartTokenSvc.cartTokens = r.cartTokens;

                    if (!this.seSvc.bindCurrencyAndCultureToDomain) {
                        this.seSvc.setCulture(r.cultureId, r.currencyId);
                    }

                    // BLOCK ADJUST CART

                    /**
                     here you can freely implement any cart-adjusting logic
                     because on Flex there is an agreement that the carts should be allways merged
                     I call merge method
                     */

                    if (r.cartTokens.length > 1) {
                        const url = `api/cart/merge/${r.cartTokens[1].cartToken}/${r.cartTokens[0].cartToken}`;
                        this.http.get<CartTokenSelector[]>(url)
                            .pipe(take(1))
                            .subscribe((mr) => {
                                this.cartTokenSvc.cartTokens = mr;
                                this.redirectAfterLogin(r.reqInfo);
                            });
                    } else {
                        this.redirectAfterLogin(r.reqInfo);
                    }

                    // BLOCK ADJUST CART
                },
                (e: HttpErrorResponse) => this.loginStatus.emit({
                    type: this.errorType,
                    message: this.badCredentialsMessage,
                    data: e
                })
            );
    }

    private getFacebookLoginHeaders(code: string): HttpHeaders {
        const code64 = new StringView(code).toBase64();
        let result = `code=${code64}`;

        return new HttpHeaders({'FacebookLogin': result});
    }
    //endregion
}

export interface ILoginConfig {
    GetHeaders(): HttpHeaders;

    LoginType(): string;

    Secret(): string;
}

export class StandardLogin implements ILoginConfig {
    constructor(private userName: string, private password: string, private secret: string) {
    }

    GetHeaders(): HttpHeaders {
        let result = `Basic type="Standard", username="${this.userName}", password="${this.password}"`;
        return new HttpHeaders({'Authorization': result});
    }

    LoginType(): string {
        return "Standard";
    }

    Secret(): string {
        return this.secret;
    }
}

export class FacebookLogin implements ILoginConfig {
    constructor(private accessToken: string, private secret: string) {
    }

    GetHeaders(): HttpHeaders {
        let result = `Basic type="Facebook", accessToken="${this.accessToken}"`;
        return new HttpHeaders({'Authorization': result});
    }

    LoginType(): string {
        return "Facebook";
    }

    Secret(): string {
        return this.secret;
    }
}

export class GoogleLogin implements ILoginConfig {
    constructor(private accessToken: string, private secret: string) {
    }

    GetHeaders(): HttpHeaders {
        let result = `Basic type="Google", accessToken="${this.accessToken}"`;
        return new HttpHeaders({'Authorization': result});
    }

    LoginType(): string {
        return "Google";
    }

    Secret(): string {
        return this.secret;
    }
}
