import {Component, OnInit} from '@angular/core';
import {GeneralDialogRef} from "../../general-dialog/common";
import {GeneralDialogConfig} from "../../general-dialog/general-dialog-config";
import {DialogResult} from "../interfaces/DialogResult";
import {IAlreadySignedUpDialogConfig} from "../interfaces/IAlreadySignedUpDialogConfig";

@Component({
    selector: 'cmp-already-signed-up-dialog',
    templateUrl: './already-signed-up-dialog.component.html',
    styleUrls: ['./already-signed-up-dialog.component.scss']
})
export class AlreadySignedUpDialogComponent implements OnInit {

    public name: string = null;
    public imageUrl: string = null;

    constructor(
        public dialogRef: GeneralDialogRef,
        public config: GeneralDialogConfig<IAlreadySignedUpDialogConfig>
    ) {
        this.name = config.data.Name;
        this.imageUrl = config.data.ImageUrl;
    }

    ngOnInit(): void {
    }

    public Continue(): void {
        this.dialogRef.close(DialogResult.Continue);
    }

    public Relogin(): void {
        this.dialogRef.close(DialogResult.New);
    }

}
