<nav *ngIf="categories?.length > 0 && !dataSvc.paHidden" class="menu" [class.menu--in-cart]="dataSvc.mainMenuHidden"  (mouseenter)="onMenuHover(true)" 
    (mouseleave)="onMenuHover(false)">
    <ul class="menu__list">
        <!-- menu__item--hover class pro hover -->
        <li *ngFor="let category of categories" hover-class="menu__item--hover" class="menu__item" [ngClass]="{'menu__item--active': isActiveClass(category.id),'menu__item--open': isActiveCategory(category.id), 'menu__item--hover': isActiveCategory(category.id)}" (mouseenter)="rootCategoryMouseEnter(category.id)">
            <a [routerLink]="['/c/' + category.id + '/' + category.seoUrl]" class="menu__link" (click)="deactivateMenu()" >
                <picture>
                    <img [src]="seSvc.settings.newImageServerUrl + '/menu/30/30' + category?.imagePath" [alt]="category?.imageAlt">
                </picture>

                <div *ngIf="category?.displayNameHtml" [innerHTML]="category?.displayNameHtml">
                </div>

                <ng-container *ngIf="!category?.displayNameHtml">
                    {{category.displayName}}
                </ng-container>
            </a>

            <div class="menu__toggle" (click)="rootCategoryClick(category.id)">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#chevron-down" />
                    </svg>
                </div>
            </div>

            <div *ngIf="category.subcategories?.length > 0" class="submenu">
                <div class="submenu__in">
                    <div class="submenu__main">
                        <div class="submenu__wrap">
                            <div *ngFor="let child of category.subcategories" class="submenu__item">
                                <a [routerLink]="['/c/' + child.id + '/' + child.seoUrl]" class="submenu__image">
                                    <picture *ngIf="child.imagePath" >
                                        <source [srcset]="(menuImagePathPrefix + '/menu/80/112/' + child.imagePath) | webp" type="image/webp" />
                                        <img [src]="menuImagePathPrefix + '/menu/80/112/' + child.imagePath" [alt]="child.imageAlt" />
                                    </picture>
                                </a>

                                <div class="submenu__content">
                                    <a [routerLink]="['/c/' + child.id + '/' + child.seoUrl]" (click)="deactivateMenu2()" class="submenu__title">
                                        {{child.displayName}}
                                    </a>

                                    <div *ngIf="child?.subcategories?.length > 0" class="submenu__list">
                                        <ng-container *ngFor="let subChild of child.subcategories">
                                            <a [routerLink]="['/c/' + subChild.id + '/' + subChild.seoUrl]" (click)="deactivateMenu2()" class="submenu__child">
                                                {{subChild.displayName}}
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="submenu__side">
                        <cmp-adva-adacemy-menu [categoryItem]="category.sideCategory"></cmp-adva-adacemy-menu>
                    </div>
                </div>
            </div>
        </li>
    </ul>

    <div class="menu__tools">
        <cmp-article-main-menu (close)="toggleMenu()"></cmp-article-main-menu>

        <!--<div class="header-currency">
            <div class="header-currency__icon">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#globe" />
                    </svg>
                </div>
            </div>

            <div class="header-currency__text">
                <strong>Česky</strong> CZK (Kč)
            </div>
        </div>-->
        <cmp-header-option></cmp-header-option>

        <a href="{{'tel: ' + sen['index-phone-number']}}" class="header-contact header-contact--responsive">
            <div class="header-contact__image">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#phone" />
                    </svg>
                </div>
            </div>

            <div class="header-contact__content">
                <div class="header-contact__text">
                    {{sen['index-opening-hours']}}
                </div>

                <div class="header-contact__value">
                    {{sen['index-phone-number']}}
                </div>
            </div>
        </a>
    </div>
</nav>
