/**
 * Component for displaying product related, alternative etc items in sick carousel.
 */
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {SettingsService} from "../../services/settings.service";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {HttpClient} from "@angular/common/http";
import {Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {loadFromLocalStorage} from "../../helpers/cookie.helper";
import {ProductCarouselSource} from "./common";
import {ProductSelector} from "../product/common";
import {CartService} from "../cart/cart.service";

declare let $: any;

export enum CarouselViewType {
    ProductBox,
    MiniProductBox,
    IndexProductBox
};

@Component({
    selector: 'cmp-product-carousel',
    templateUrl: '../../tpl/product-carousel.html',
    styleUrls: ['../../assets/styles/2-components/product-list.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductCarouselComponent extends Translatable implements OnChanges {

    public CarouselViewType = CarouselViewType;

    @Input() viewType: CarouselViewType = CarouselViewType.ProductBox;

    @Input() productId: number;
    @Input() productCarouselSource: ProductCarouselSource;
    @Input() titleSentenceKey: string;
    @Input() fullContent: boolean = true;
    @Input() showFlags: boolean = true;
    @Input() pageSize: number = null;
    @Output() noProductsFound: EventEmitter<boolean> = new EventEmitter();
    @Output() productChanged: EventEmitter<ProductSelector[]> = new EventEmitter();

    items: ProductSelector[] = [];
    selector: string;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        private http: HttpClient,
        private cartSvc: CartService
    ) {
        super(dataSvc, seSvc);
    }

    slickOps(): void {
        try {
            $(this.selector).slick('unslick');
        } catch (e) {
        }

        if(this.viewType === CarouselViewType.MiniProductBox) {
            // roman-mini-box
            $(this.selector).slick({
                arrows: true,
                infinite: false,
                rows: 0,
                slidesToShow: 4,
                slidesToScroll: 1,
                prevArrow: `<button class="slick-arrow slick-arrow--prev"><div class="icon"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-left" /></svg></div></button>`,
                nextArrow: `<button class="slick-arrow slick-arrow--next"><div class="icon"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-right" /></svg></div></button>`,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
        } else {
            $(this.selector).slick({
                arrows: true,
                infinite: false,
                rows: 0,
                slidesToShow: 5,
                slidesToScroll: 1,
                prevArrow: `<button class="slick-arrow slick-arrow--prev"><div class="icon"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-left" /></svg></div></button>`,
                nextArrow: `<button class="slick-arrow slick-arrow--next"><div class="icon"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#arrow-right" /></svg></div></button>`,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2
                        }
                    }
                ]
            });
        }

        $(this.selector).slick('slickGoTo', 0, false);
    }

    onLast(): void {
        try {
            for (let i = 0; i < 100; i++) {
                $(this.selector).slick('slickRemove', i)
            }
        } catch (e) {
        }
        let tim = setTimeout(() => {
            clearTimeout(tim);
            this.slickOps()
        }, 0);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['productId'] && changes['productId'].currentValue
            && this.productCarouselSource != "recent"
            && this.productCarouselSource != "recommended"
            && this.productCarouselSource != "cart"
            && this.productCarouselSource != "newInSales"
        ) {
            this.getRelated();
        }
    }

    ngOnInit(): void {
        // recently viewed products
        if (this.productCarouselSource === "recent") {
            let recent: number[] = <number[]>loadFromLocalStorage('recp');
            if (!recent) {
                this.noProductsFound.emit(true);
                return;
            }

            let qs: string = '';
            recent.forEach((elem, index) => {
                qs += index === 0 ? `ids=${elem}` : `&ids=${elem}`;
            });
            this.http.get<ProductSelector[]>(`api/product/getRecent?${qs}`)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(res => {
                    this.items = res;
                    if (!this.items || this.items.length === 0) this.noProductsFound.emit(true);
                })
        }
        // recommended products
        if (this.productCarouselSource === "recommended") {
            this.http.get<ProductSelector[]>(`api/index/getRecommendedProducts`)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    res => {
                        this.items = res;
                        if (!this.items || this.items.length === 0) {
                            this.noProductsFound.emit(true);
                        }
                    }
                )
        }
        if (this.productCarouselSource === 'cart') {
            this.cartSvc.cartContentChanged
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.GetCartCarouselData();
                });

            this.GetCartCarouselData();
        }

        if (this.productCarouselSource === 'newInSales'){
            this.http.get<ProductSelector[]>(`api/index/getProductsNewInSales?pageSize=${this.pageSize}`)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    res => {
                        this.items = res;
                        if (!this.items || this.items.length === 0) {
                            this.noProductsFound.emit(true);
                        }
                    }
                )
        }
        this.selector = `#${this.productCarouselSource}`;
    }

    private subs: Subscription = null;

    private GetCartCarouselData(): void {
        if (!this.subs) {
            this.subs = this.http.get<ProductSelector[]>('api/product/get-cart-carousel-products')
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(prds => {
                    if (this.selector) {
                        $(this.selector).slick('unslick');
                    }

                    setTimeout(() => {
                        this.items = prds;
                        this.subs = null;
                        this.productChanged.emit(this.items);
                    });
                });
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private getRelated(): void {
        this.http.get<ProductSelector[]>(`api/product/get${this.productCarouselSource}/${this.productId}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                this.items = res;
                if (this.items.length === 0) this.noProductsFound.emit(true);
            });
    }

}
