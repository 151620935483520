export interface IInterval {
    start: number;
    end: number;
}

export class Interval {
    public static findIntervalIntersect(interval1: IInterval, interval2: IInterval): IInterval | null {
        if (interval1.start > interval2.end || interval1.end < interval2.start) {
            // No intersection
            return null;
        }


        const intersect: IInterval = {
            start: Math.max(interval1.start, interval2.start),
            end: Math.min(interval1.end, interval2.end),
        };


        return intersect;
    }

    public static findIntervalUnion(interval1: IInterval, interval2: IInterval): IInterval {
        const union: IInterval = {
            start: Math.min(interval1.start, interval2.start),
            end: Math.max(interval1.end, interval2.end),
        };

        return union;
    }

    public static compareIntervals(interval1: IInterval, interval2: IInterval): boolean {
        return interval1.start === interval2.start && interval1.end === interval2.end;
    }
}



