import {Pipe, PipeTransform} from "@angular/core";
import {ProductSelector, ProductVariantDetailSelector} from "../product/common";
import {ProductAmountAdapter, ProductVariantAmountAdapter} from "../../helpers/ProductAmountAdapter";
import {IProductAmountSettings} from "./interfaces/IProductAmountSettings";
import {ProductVariantBriefSelector} from "../../interfaces/general";

@Pipe({name: 'ProductAmount'})
export class ProductAmountPipe implements PipeTransform {

    transform(product: ProductSelector): IProductAmountSettings {
        if (!product)
            return null;

        if((<any>product).id) {
            return new ProductVariantAmountAdapter(<ProductVariantBriefSelector><any>product);
        } else {
            return new ProductAmountAdapter(<ProductSelector>product);
        }
    }

}

@Pipe({name: 'VariantAmount'})
export class VariantAmountPipe implements PipeTransform {

    transform(product: ProductVariantDetailSelector): IProductAmountSettings {
        if (!product)
            return null;

        return new ProductVariantAmountAdapter(<ProductVariantBriefSelector><any>product);
    }

}