<div class="user" [class]="classModificator ? 'user--'+classModificator : ''">
    <ng-container *ngIf="!loggedIn; else elseLogin">
        <a href="javascript:void(0);" (click)="showDialog()" class="user__link" [title]="sen['app-login']">
            <div class="user__icon">
                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#user" />
                    </svg>
                </div>
            </div>

            <div class="user__text">
                <!--
                {{sen['app-login']}}
                -->
                {{s('login--login-registration')}}
            </div>
        </a>
    </ng-container>

    <ng-template #elseLogin>
        <div (mouseenter)="onMouseEnterMain()">
            <a [routerLink]="['/muj-ucet']" class="user__link">
                <div class="user__icon">
                    <div class="icon">
                        <svg>
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#user" />
                        </svg>
                    </div>
                </div>

                <div class="user__text">
                    {{displayUser}}
                </div>
            </a>

            <div class="menu-container" (mouseenter)="onMouseEnterDropdown()" (mouseleave)="onMouseLeaveDropdown()">
                <cmp-account-menu [isDropdownMenu]="true" [isSide]="false" [isDropdownOpen]="isDropdownMenuOpen"></cmp-account-menu>
            </div>
        </div>
    </ng-template>
</div>