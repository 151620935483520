
/**
 * Re-Created by jiri.kopel on 06.03.2019.
 */

import {LuigiSuggestComponent} from "./luigi-suggest.component";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {UiDirectivesModule} from "../../directives/ui-directives.module";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UiDirectivesModule
    ],
    declarations: [LuigiSuggestComponent],
    exports: [LuigiSuggestComponent]
})

export class LuigiSuggestModule {}