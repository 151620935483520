import {Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';
import {ArticleBaseSelector, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";


@Component({
    templateUrl: '../../tpl/article-main-menu.html',
    selector: 'cmp-article-main-menu',
    styleUrls: ['../../assets/styles/3-layout/nav-links.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ArticleMainMenuComponent extends Translatable {
    articles: ArticleBaseSelector[];
    @Output() noArticles: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() close: EventEmitter<null> = new EventEmitter<null>();
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService,
                private http: HttpClient) {
        super(dataSvc, seSvc);
    }

    getArticles(): void {
        this.http.get<ArticleBaseSelector[]>(`api/article/getMainMenuLinks`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                res => {
                    this.articles = res;
                    if (!this.articles || this.articles.length === 0) {
                        this.noArticles.emit(true);
                    }
                },
                () => {
                    this.noArticles.emit(true);
                }
            );
    }

    ngOnInit(): void {
        this.getArticles();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    closeMenu(){
        this.close.emit();
    }
}
