import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {AccountMenuComponent} from "./account-menu.component";
import {RouterModule} from "@angular/router";
import {AskExpertModule} from "../ask-expert/ask-expert.module";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AskExpertModule
    ],
    declarations: [
        AccountMenuComponent
    ],
    exports: [
        AccountMenuComponent
    ],
    providers: []
})

export class AccountMenuModule {}
