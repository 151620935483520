import {IProductAmountSettings} from "../modules/product-amount/interfaces/IProductAmountSettings";
import { CartItemSelectorFull } from "../modules/cart/common";

export class ProductAmountCartAdapter implements IProductAmountSettings {

    constructor(product: CartItemSelectorFull) {
        this.Factor = product.productDetail.factor || 1;
        this.MaxQuantity = product.productDetail.maxQtyOrder;
        this.MinQuantity = product.productDetail.minQtyOrder || 1;
        this.ProductId = product.productDetail.id;
        this.Unit = product.productDetail.unit?.shortCut;
    }

    Factor: number;
    MaxQuantity: number;
    MinQuantity: number;
    ProductId: number;
    Unit: string;

}