import {Injectable} from "@angular/core";
import {ActivatedRoute, Params, QueryParamsHandling, Router} from "@angular/router";
import {StringIndexedObject} from "../interfaces/general";

@Injectable({providedIn: 'any'})
export class PageStateUrlService {
    private params: Params;

    constructor(private activatedRoute: ActivatedRoute, private router: Router) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.params = params;
        });
    }

    getParameters(): StringIndexedObject<string | Array<string> | number | Array<number>> {
        return this.params;
    }

    getParam(paramName: string): any {
        return this.params[paramName];
    }

    setParams(queryParams: StringIndexedObject<string | Array<string> | number | Array<number>>) {
        this.navigate(queryParams, 'merge');
    }

    private navigate(queryParams: StringIndexedObject<string | Array<string> | number | Array<number>>, queryParamsHandling: QueryParamsHandling) {
        //type QueryParamsHandling = 'merge' | 'preserve' | ''


        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: queryParamsHandling,
            // preserve the existing query params in the route
            skipLocationChange: false,
            // do not trigger navigation

            replaceUrl: false
        });
    }
}
