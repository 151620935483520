<div class="product-box-mini product-box-vk-{{item.variantKey}}" [ngClass]="{'product-box-mini--not-available': item.accessLevel !== 2}" *ngIf="item">
    <a [routerLink]="[item?.urlToDisplay]" class="product-box-mini__image" (click)="onClickDetail()" [id]="item?.variantKey">
        <picture>
            <source [srcset]="(imageServerUrl + '/catalog/100/100/' + item?.imagePath) | webp" type="image/webp"/>
            <img [src]="imageServerUrl + '/catalog/100/100/' + item?.imagePath" [alt]="item?.imageTitle?item?.imageTitle:item?.name" [title]="item?.imageTitle?item?.imageTitle:''"/>
        </picture>
    </a>

    <div class="product-box-mini__content">
        <a [routerLink]="[item?.urlToDisplay]" (click)="onClickDetail()" class="product-box-mini__title">
            {{item?.name}}
        </a>

        <div class="product-box-mini__prices">
            <div class="prices">
                <div class="prices__item prices__item--main">
                    <cmp-price [price]="item.priceWithVAT"></cmp-price>
                </div>
            </div>
        </div>

        <div class="product-box-mini__buy" *ngIf="displayShoppingSection">
            <button [disabled]="dataSvc.dataLoading || item?.priceWithVAT === 0" [ngClass]="{'btn--gray': item?.priceWithVAT === 0}" class="product-box-mini__btn btn btn--medium btn--green" (click)="buy(item.variantId, item.minQtyOrder)" data-action="buy">
                {{sen['cart-buy']}}

                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cart"/>
                    </svg>
                </div>
            </button>
        </div>
    </div>
</div>
