import {Injectable} from '@angular/core';
import {IHeaderInvoiceResult} from "../commons/commons";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class HeaderInvoiceService {

    constructor(
        private http: HttpClient
    ) {
    }

    public GetHeaderInvoiceInfo(): Observable<IHeaderInvoiceResult> {
        return this.http.get<IHeaderInvoiceResult>('api/invoice/get-header-invoice-info');
    }
}
