import {Component, HostListener, Input, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {DigestService} from "../../services/digest.service";
import {AccountMenuItemsService} from "./account-menu.service";
import {Router} from "@angular/router";

declare let $: any;

@Component({
    selector: 'cmp-account-menu',
    templateUrl: './account-menu.html',
    styleUrls: ['../../assets/styles/4-routes/account/account-menu.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AccountMenuComponent extends Translatable {

    @Input() isSide: boolean;
    @Input() isDropdownMenu: boolean = false;
    @Input() isDropdownOpen: boolean = false;
    navExpanded: boolean;
    isMouseOverDropdown: boolean = false;

    constructor(
        public dataSvc: DataService,
        public seSvc: SettingsService,
        public accMenuSvc: AccountMenuItemsService,
        private digestSvc: DigestService,
        private router: Router
    ) {
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.accMenuSvc.setActive(this.router.url);
    }

    toggleNav(): void {
        this.navExpanded = !this.navExpanded;
        if (this.navExpanded) {
            $('.js-nav').slideDown(250);
        } else {
            $('.js-nav').slideUp(250);
        }
    }

    hideDropdown() {
        // Only hide the dropdown if the mouse is not over the dropdown content
        if (!this.isMouseOverDropdown) {
            this.isDropdownOpen = false;
        }
    }

    showDropdown() {
        this.isMouseOverDropdown = true;
        this.isDropdownOpen = true;
    }

    @HostListener('window:scroll', [])
    isPageScrolled(): boolean {
        return window.scrollY > 84;
    }

    public SignOut(): void {
        this.digestSvc.logOut(true);
    }
}
