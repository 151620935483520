<div class="product-row product-box-vk-{{item.variantKey}}" *ngIf="item">
    <div class="product-row__main">
        <div class="product-row__image">
            <a [routerLink]="[item?.urlToDisplay]" (click)="onClickDetail()">
                <picture>
                    <source [srcset]="(imageServerUrl + '/catalog/60/60/' + item?.imagePath) | webp" type="image/webp"/>
                    <img [src]="imageServerUrl + '/catalog/60/60/' + item?.imagePath" [alt]="item?.imageTitle?item?.imageTitle:item?.name" [title]="item?.imageTitle?item?.imageTitle:''"/>
                </picture>
            </a>
        </div>

        <div class="product-row__content">
            <a [routerLink]="[item?.urlToDisplay]" (click)="onClickDetail()" class="product-row__title">
                {{item.name}}
            </a>

            <div class="product-row__availability">
                <cmp-availability [availability]="item | ProductBoxModelAvailability"></cmp-availability>
            </div>
        </div>
    </div>

    <ng-container *ngIf="IsVariant == false">
        <cmp-wish-list-button [type]="'product-row'" [variantId]="item.variantId"></cmp-wish-list-button>
    </ng-container>

    <div class="product-row__prices">
        <div class="prices prices--right">
            <div class="prices__item prices__item--main">
                <cmp-price [price]="item.priceWithVAT"></cmp-price>
            </div>

            <div class="prices__item prices__item--base">
                <cmp-price [price]="item.priceWithoutVAT"></cmp-price>
                {{sen['without-vat']}}
            </div>
        </div>
    </div>



    <ng-container *ngIf="IsVariant === false; else elseNoVariant">
        <div class="product-row__buy">
            <cmp-product-amount #amount [amount]="item?.count" [data]="item | ProductBoxModelAmount" (confirmed)="changeCount($event.Amount)" (amountChange)="changeCount($event)"></cmp-product-amount>

            <button [disabled]="!amount.IsValid || dataSvc.dataLoading || item?.priceWithVAT == 0" class="product-box__btn btn-cart btn-cart--medium" (click)="buy(item.variantId, amount.Amount)" data-action="buy" [ngClass]="{'btn--gray': item.priceWithVAT == 0}">
                {{sen['cart-buy']}}

                <div class="icon">
                    <svg>
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cart"/>
                    </svg>
                </div>
            </button>
        </div>
    </ng-container>

    <ng-template #elseNoVariant>
        <div class="product-row__buy product-row__buy--center">
            <a [routerLink]="[item?.urlToDisplay]" (click)="onClickDetail()" class="btn btn--medium btn--gray">
                {{sen['detail-button--label']}}
            </a>
        </div>
    </ng-template>
</div>