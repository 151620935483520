import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AskExpertComponent} from './ask-expert/ask-expert.component';
import {RouterModule} from "@angular/router";

@NgModule({
    declarations: [
        AskExpertComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [
        AskExpertComponent
    ]
})
export class AskExpertModule {
}
