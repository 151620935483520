<div [formGroup]="userForm" class="modal-login" (keyup)="onKey($event)">
    <div class="modal-login__form">
        <div class="modal-login__item form-item">
            <input formControlName="userName" class="form-input" id="login" type="text" [placeholder]="sen['login-email']" />

            <label class="form-label" for="login">
                {{sen['login-email']}}:
            </label>

            <div class="validation" *ngIf="userForm.controls['userName'].invalid && userForm.controls['userName'].dirty">
                <cmp-val-msg [msg]="sen['login-invalid-email']"></cmp-val-msg>
            </div>
        </div>

        <div class="modal-login__item form-item">
            <input formControlName="password" class="form-input" id="password" type="password" [placeholder]="sen['login-password']" />

            <label class="form-label" for="password">
                {{sen['login-password']}}:
            </label>

            <div class="validation">
                <cmp-val-msg *ngIf="userForm.controls['password'].invalid && userForm.controls['password'].dirty" [msg]="sen['login-invalid-password']"></cmp-val-msg>
                <cmp-val-msg *ngIf="isError && !activated" [msg]="sen['login-not-activated']"></cmp-val-msg>
                <cmp-val-msg *ngIf="isError && activated" [msg]="sen['login-error']"></cmp-val-msg>
                <cmp-val-msg *ngIf="captchaFailed" [msg]="sen['app-captcha-failed']"></cmp-val-msg>
            </div>
        </div>
    </div>

    <div class="modal-login__actions">
        <button (click)="executeCaptcha()" [disabled]="!userForm.valid" [class.disabled]="!userForm.valid" class="btn btn--large btn--red">
            {{sen['app-login']}}
        </button>

        <a [routerLink]="['/zapomenute-heslo']" class="modal-login__link" (click)="closeDialog()">
            {{sen['login-forgot-password']}}
        </a>

        <cmp-facebook-sign-up-button></cmp-facebook-sign-up-button>
        <cmp-google-sign-up-button></cmp-google-sign-up-button>
    </div>

    <div class="modal-login__bottom">
        <a [routerLink]="['/registrace']" (click)="closeDialog()" class="btn btn--small btn--gray">
            {{sen['registration-want-register']}}
        </a>
    </div>
</div>
