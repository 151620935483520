<div class="socials" *ngIf="data?.facebook || data?.instagram || data?.linkedIn || data?.twitter || data?.pinterest || data?.youTube">
    <a *ngIf="data?.facebook" [href]="data?.facebook" title="Facebook" target="_blank" class="socials__item" rel="noopener">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#facebook" />
            </svg>
        </div>
    </a>

    <a *ngIf="data?.instagram" [href]="data?.instagram" title="Instagram" target="_blank" class="socials__item" rel="noopener">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#instagram" />
            </svg>
        </div>
    </a>

    <a *ngIf="data?.linkedIn" [href]="data?.linkedIn" title="LinkedIn" target="_blank" class="socials__item" rel="noopener">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#linkedin" />
            </svg>
        </div>
    </a>

    <a *ngIf="data?.twitter" [href]="data?.twitter" title="Twitter" target="_blank" class="socials__item" rel="noopener">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#twitter" />
            </svg>
        </div>
    </a>

    <a *ngIf="data?.pinterest" [href]="data?.pinterest" title="Pinterest" target="_blank" class="socials__item" rel="noopener">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#pinterest" />
            </svg>
        </div>
    </a>

    <a *ngIf="data?.pinterest" [href]="data?.youTube" title="YouTube" target="_blank" class="socials__item" rel="noopener">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#youtube" />
            </svg>
        </div>
    </a>
</div>
