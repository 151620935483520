import {Pipe, PipeTransform} from "@angular/core";
import {ProductBoxModelAmountAdapter, ProductVariantAmountAdapter} from "../../helpers/ProductAmountAdapter";
import {IProductAmountSettings} from "./interfaces/IProductAmountSettings";
import {ProductVariantBriefSelector} from "../../interfaces/general";
import {IProductBoxModel} from "../product/common";

@Pipe({name: 'ProductVariantAmount'})
export class ProductVariantAmountPipe implements PipeTransform {

    transform(product: ProductVariantBriefSelector): IProductAmountSettings {
        if (!product)
            return null;

        return new ProductVariantAmountAdapter(product);
    }

}

@Pipe({name: 'ProductBoxModelAmount'})
export class ProductBoxModelAmountPipe implements PipeTransform {

    transform(product: IProductBoxModel): IProductAmountSettings {
        if (!product)
            return null;

        return new ProductBoxModelAmountAdapter(product);
    }

}