import {Component, ViewEncapsulation} from '@angular/core';
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {CartService} from "../cart/cart.service";
import {CompareProductsService} from "../../services/compare-products.service";
import {PageStateService} from "../../services/page-state.service";
import {ProductBoxComponent} from "./product-box.component";
import {AvailabilityService} from "../availability/services/availability.service";

declare let $: any;

@Component({
    selector: 'cmp-mini-product-box',
    templateUrl: '../../tpl/mini-product-box.html',
    styleUrls: ['../../assets/styles/2-components/product-box-mini.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MiniProductBoxComponent extends ProductBoxComponent {

    constructor(
        dataSvc: DataService,
        seSvc: SettingsService,
        cartSvc: CartService,
        compareSvc: CompareProductsService,
        pageStateSvc: PageStateService,
        availSvc: AvailabilityService
    ) {
        super(dataSvc, seSvc, cartSvc, compareSvc, pageStateSvc, availSvc);
    }
}


