import {EventEmitter, Injectable} from "@angular/core";
import {filter} from "rxjs/operators";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {loadFromSession, removeFromSession, saveToSession} from "../helpers/cookie.helper";
import {StringIndexedObject} from "../interfaces/general";

@Injectable({
    providedIn: 'root'
})
export class PageStateService {

    navigatedUrl: string;

    navigationStartEvent: EventEmitter<NavigationStart> = new EventEmitter<NavigationStart>();

    isBrowserBackButton: boolean = false;


    private get pageOffsetSessionKey(): string {
        return 'pageOffset';
    }

    private get pageOffsetObjectKey(): string {
        return 'pageOffset' + this.navigatedUrl;
    }

    private getCachedValue(): number {
        const sessionObject: StringIndexedObject<number> = loadFromSession(this.pageOffsetSessionKey);
        if (!sessionObject) {
            return null;
        }
        return sessionObject[this.pageOffsetObjectKey];
    }

    private setCachedValue(value: number): void {
        let sessionObject: StringIndexedObject<number> = loadFromSession(this.pageOffsetSessionKey);
        if (!sessionObject) {
            sessionObject = {};
        }
        sessionObject[this.pageOffsetObjectKey] = value;
        saveToSession(this.pageOffsetSessionKey, sessionObject);
    }


    set pageTopOffset(offset: number) {
        if (offset == null) {
            removeFromSession(this.pageOffsetSessionKey);
            return;
        }
        this.setCachedValue(offset);
    }

    get pageTopOffset(): number {
        return this.getCachedValue();
    }

    scrollReason: ScrollReason;

    constructor(private router: Router) {
        this.navigatedUrl = this.router.url;
        this.router.events
            .pipe(filter(evt => evt instanceof NavigationStart))
            .subscribe((ev: NavigationStart) => {
                if (ev && ev.restoredState) {
                    this.isBrowserBackButton = true;
                } else {
                    this.isBrowserBackButton = false;
                }

                this.navigationStartEvent.emit(ev);
            });

        this.router.events
            .pipe(filter(evt => evt instanceof NavigationEnd))
            .subscribe((ev: NavigationStart) => {
                this.navigatedUrl = ev.url;
            });

    }
}

export enum ScrollReason {
    Detail,
    Pager
}