import {Injectable} from "@angular/core";
import {AccountMenuItems, IAccountMenuItem} from "./common";
import {CredentialStorage} from "../../services/credential-storage.service";

@Injectable()
export class AccountMenuItemsService {

    menuItems: IAccountMenuItem[];

    constructor() {
        this.menuItems = [
            {
                sideItem: AccountMenuItems.account_order_list,
                routerLink: '/muj-ucet/objednavky',
                iconActive: 'assets/img/account/order-list-active.svg',
                iconNotActive: 'assets/img/account/order-list.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_invoice_list,
                routerLink: '/muj-ucet/faktury',
                iconActive: 'assets/img/account/invoices-active.svg',
                iconNotActive: 'assets/img/account/invoices.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_settings,
                routerLink: '/muj-ucet/nastaveni',
                iconActive: 'assets/img/account/settings-active.svg',
                iconNotActive: 'assets/img/account/settings.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_collaborators,
                routerLink: '/muj-ucet/spolupracovnici',
                iconActive: 'assets/img/account/co-workers-active.svg',
                iconNotActive: 'assets/img/account/co-workers.svg',
                isShownInTop: CredentialStorage.authInfo.isB2B,
                isShownInSide: CredentialStorage.authInfo.isB2B
            },
            {
                sideItem: AccountMenuItems.account_profile,
                routerLink: '/muj-ucet/osobni-udaje',
                iconActive: 'assets/img/account/user-edit-active.svg',
                iconNotActive: 'assets/img/account/user-edit.svg',
                isShownInTop: true,
                isShownInSide: true
            },
            {
                sideItem: AccountMenuItems.account_delivery_addresses,
                routerLink: '/muj-ucet/dodaci-adresy',
                iconActive: 'assets/img/account/order-list-active.svg',
                iconNotActive: 'assets/img/account/order-list.svg',
                isShownInTop: false,
                isShownInSide: false
            },
            {
                sideItem: AccountMenuItems.account_wishlists,
                routerLink: '/muj-ucet/seznamy-prani',
                iconActive: 'assets/img/account/wishlists-active.svg',
                iconNotActive: 'assets/img/account/wishlists.svg',
                isShownInTop: true,
                isShownInSide: true
            }
        ];
    }

    setActive(link: string): void {
        this.menuItems.forEach(x => {
            x.isActive = link.indexOf(x.routerLink) == 0;
        });
    }
}