import {Injectable} from "@angular/core";
import {
    loadFromLocalStorage, removeFromLocalStorage,
    saveToLocalStorage
} from "../../helpers/cookie.helper";
import {CartTokenSelector} from "../../interfaces/general";

@Injectable()
export class CartTokenService {

    constructor() {
        this._tokens = loadFromLocalStorage(this._tokensLocalStorageKey);
    }

    private _tokensLocalStorageKey: string = 'cartTokens';
    private _tokens: Array<CartTokenSelector>;

    set cartTokens(value: Array<CartTokenSelector>) {
        this._tokens = value;
        if (value && value.length) {
            saveToLocalStorage(this._tokensLocalStorageKey, value);
        } else {
            removeFromLocalStorage(this._tokensLocalStorageKey);
        }
    }

    get cartTokens(): Array<CartTokenSelector> {
        return this._tokens;
    }

    get cartToken(): string {
        if (this.cartTokens && this.cartTokens.length) {
            return this.cartTokens[0].cartToken;
        }
        return null;
    }

    clear(): void {
        this.cartTokens = null;
    }

}
